import Vue from 'vue'
import App from './App.vue'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.min.css'
import 'vuetify/dist/vuetify.min.css'
import store from './store'
import moment from 'moment'

moment.locale('ru')
import Vuetify from 'vuetify'
import ru from 'vuetify/es5/locale/ru'
import VueTheMask from 'vue-the-mask'

Vue.use(VueTheMask)
Vue.prototype.$moment = moment;
Vue.config.productionTip = false
Vue.use(Vuetify)
let eventBus = new Vue();
Vue.prototype.$eventBus = eventBus;
new Vue({
    store,
    render: h => h(App),
    vuetify: new Vuetify({
        lang: {
            locales: {ru},
            current: 'ru'
        },
        theme: {
            dark: localStorage.getItem('dark_mode'),
        },
    }),
    watch: {
        '$vuetify.theme.dark': (value) => localStorage.setItem('dark_mode', value ? 1 : '')
    },
    methods: {
        selectValues(array, skipEmpty = true) {
            let result = [];
            for (let i in array) {
                if (skipEmpty && !array[i]) continue;
                result.push({name: array[i], value: i})
            }
            return result;
        },
        printCost(sum, withPlus = false) {
            sum = parseInt(sum);
            let minus = sum < 0;
            sum = Math.abs(sum);
            if (!sum) return 0 + this.$store.state.user.city.country.currency;
            sum += '';
            let l = sum.length;
            let part1 = sum.substr(0, l % 3);
            let part2 = sum.substr(l % 3);
            let parts = [];
            if (part1) parts.push(part1);
            part2 = part2.match(/.{1,3}/g);
            if (part2) parts.push(...part2);
            return (minus ? '-' : (withPlus ? '+' : '')) + parts.join(' ') + this.$store.state.user.city.country.currency;
        },
        outputRange(value) {
            let string = '';
            if (value && value[0]) string += 'с ' + this.$root.dateToRus(value[0]);
            if (value && value[1]) string += ' по ' + this.$root.dateToRus(value[1]);
            return string;
        },
        getData(page, name, forCurrentCity = false) {
            let val = localStorage.getItem(`data_${forCurrentCity ? this.$store.state.user.city.id + '_' : ''}${page}_${name}`);
            if (!val) return null;
            else return JSON.parse(val);
        },
        saveData(page, name, data, forCurrentCity = false) {
            localStorage.setItem(`data_${forCurrentCity ? this.$store.state.user.city.id + '_' : ''}${page}_${name}`, JSON.stringify(data));
        },
        dateToRus(date, withoutYear = false, yearShort = false) {
            if (!date) return '';
            let year, month, day;
            [year, month, day] = date.split('-');
            try {
                if (withoutYear) return day + '.' + month;
                else if (yearShort) return day + '.' + month + '.' + (year % 1000);
                else return day + '.' + month + '.' + (year % 1000);
            } catch (e) {
                console.error(e, date)
            }
        },
        round(x) {
            x = parseFloat(x);
            if (isNaN(x)) return 0;
            return Math.round(x * 100) / 100;
        },
    }
}).$mount('#app')
