<template>
  <v-chip :color="color">
    <span style="margin-right: 5px" v-if="title">
      Всего
    </span>
    <v-icon v-else-if="icon" size="20" style="margin-right: 5px" color="rgba(0,0,0,.6)">mdi-{{ icon }}</v-icon>
    <span :style="`color: ${parseInt(this.value)>=0? 'green' : 'red'}`">{{ $root.printCost(value, 1) }}</span>
  </v-chip>
</template>
<script>

export default {
  name: "SumChip",
  props: {
    value: {default: '0'},
    icon: {default: ''},
    title: {default: ''},
    color: {default: 'transparent'},
  },
}
</script>