<template>
  <div class="titr" style="height: 80vh;padding: 20px;display: flex;justify-content: center;">
    <div>
      <div v-if="field"
           :style="{width: field.width*(63+5)+'px', height: field.height*(63+5)+'px'}"
           style="position: relative"
      >
        <div style="position: absolute;" v-for="(column, columnIndex) in field.cells" :key="columnIndex">
          <div class="cell" style="position: absolute;"
               @click="cellClick(columnIndex, cellIndex)"
               :style="{left: columnIndex*(63+5)+'px', top: cellIndex*(63+5)+'px', background: chosenCell && chosenCell[0]===columnIndex && chosenCell[1]===cellIndex ? '#f00':bestMove && [bestMove.cells[0][0], bestMove.cells[1][0]].includes(columnIndex) && [bestMove.cells[0][1], bestMove.cells[1][1]].includes(cellIndex) ? '#eee':'#fff'}"
               v-for="(cell, cellIndex) in column"
               :key="cell.id">
            {{ cell.icon }}
          </div>
        </div>
      </div>
      <div style="display: flex;gap: 10px;margin-top: 40px">
        <div @click="(iconToFill=iconToFill===icon?'':icon);$forceUpdate();" class="cell" v-if="field"
             :style="{background: iconToFill===icon ? '#eee':'#fff'}"
             v-for="icon in field?.icons"
             :key="icon">
          {{ icon }}
        </div>
      </div>
    </div>
    <div style="display: flex;
    flex-direction: column;
    gap: 10px;">
      <v-chip>Score: {{ field?.score }}</v-chip>
      <v-btn @click="field.reduce()">Reduce</v-btn>
      <v-btn @click="findBestMove">Best move</v-btn>
      <v-btn @click="save">Save</v-btn>
      <div>Best score: {{ bestMove?.max }}</div>
    </div>
  </div>
</template>

<script>
import {Field, FindBestMove} from "@/titr";

export default {
  name: "Titr",
  data() {
    return {
      field: null,
      bestMove: null,
      chosenCell: null,
      iconToFill: '',
    }
  },
  mounted() {

    this.field = new Field(() => this.$forceUpdate(), JSON.parse(localStorage.getItem('titr_cells')));
    console.log(this.field);
    console.log(JSON.stringify(this.field.getCells()));
    this.findBestMove();
  },
  methods: {
    cellClick(columnIndex, cellIndex) {
      if (this.chosenCell) {
        let tmp = this.field.cells[columnIndex][cellIndex].icon
        this.field.cells[columnIndex][cellIndex].icon = this.field.cells[this.chosenCell[0]][this.chosenCell[1]].icon;
        this.field.cells[this.chosenCell[0]][this.chosenCell[1]].icon = tmp;
        this.chosenCell = null;
      } else if (this.iconToFill) {
        this.field.cells[columnIndex][cellIndex].icon = this.iconToFill
      } else {
        this.chosenCell = [columnIndex, cellIndex];
      }
      this.field.reduceAll();
      this.field.fill()
      this.$forceUpdate();
    },
    findBestMove() {
      let findBestMove = new FindBestMove(this.field.cells);
      this.bestMove = findBestMove.run();
    },
    save() {
      localStorage.setItem('titr_cells', JSON.stringify(this.field?.getCells()));
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Arial;
  background: #fff3ed;
}

.titr {
  .cell {
    transition: 300ms top;
    width: 63px;
    height: 63px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: white;
    font-size: 42px;
    cursor: pointer;
  }
}
</style>
