<template>
  <div id="app">
    <FiveLetters2 v-if="path.includes('5letters2')"/>
    <Titr v-else-if="path.includes('titr')"/>
    <FiveLetters v-else-if="path.includes('5letters')"/>
    <BalanceTable v-else/>
  </div>
</template>

<script>

import BalanceTable from "./views/balance/Table";
import FiveLetters from "@/views/FiveLetters";
import FiveLetters2 from "@/views/FiveLetters2";
import Titr from "@/views/Titr.vue";
export default {
  name: 'App',
  components: {
    Titr,
    FiveLetters2,
    FiveLetters,
    BalanceTable,
  },
  data() {
      return {
        path: location.pathname
      }
  },
}
</script>

<style lang="scss">
#app {
  .v-select.v-text-field--outlined:not(.v-text-field--single-line) .v-select__selections {
    padding: 0;
  }

  .v-text-field--filled > .v-input__control > .v-input__slot, .v-text-field--full-width > .v-input__control > .v-input__slot, .v-text-field--outlined > .v-input__control > .v-input__slot {
    min-height: 0;
  }

  .v-application--is-ltr .v-text-field .v-input__append-inner, .v-application--is-rtl .v-text-field .v-input__prepend-inner {
    margin-top: 5px;
  }

  .v-text-field--outlined .v-label {
    top: 6px;
  }
}
</style>
