<template>
  <v-app style="background: url('https://sd.khromov.su/back.png');background-size: cover;">
    <div style="position: absolute;top:0;left:0;width: 100%;height:100%;backdrop-filter: blur(2px);"></div>
    <v-container style="max-width: 1400px" v-if="!hideAll">
      <v-checkbox v-if="0" label="Dark theme" v-model="$vuetify.theme.dark"/>
      <v-row>
        <v-col>
          <v-card>
            <v-card-text>
              <DatePeriod @change="loadWorktime" v-model="filters.period"/>
              <v-btn icon class="ml-2" @click="$refs.WorktimeEdit.open()">
                <v-icon>mdi-plus</v-icon>
              </v-btn>
              <v-chip small>{{stats.hours}}ч {{stats.income}}р</v-chip>
              <v-data-table
                  hide-default-footer
                  disable-pagination
                  :headers="[{value: 'id', text: '№'},{value: 'date', text: 'Дата'},{value: 'hours', text: 'Часов'},{value: 'client', text: 'Клиент'},{value: 'comment', text: 'Коммент'},]"
                  :items="worktime">
                <template v-slot:item.client="{item}">
                  <td @click="$refs.WorktimeEdit.open(item)">{{ $store.state.clients.find(c => c.id == item.client).name }}</td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
          <v-card class="mt-4">
            <v-card-text>
              <v-data-table
                  hide-default-footer
                  :headers="[{value: 'hours', text: 'Часов'},{value: 'client', text: 'Клиент'},{value: 'actions', text: 'Действия'},]"
                  :items="worktimeUnmanaged">
                <template v-slot:item.client="{item}">
                  <td>{{ $store.state.clients.find(c => c.id == item.client).name }}</td>
                </template>
                <template v-slot:item.actions="{item}">
                  <td>
                    <v-btn
                        @click="$refs.edit.open({sum: item.hours*$store.state.clients.find(c=>c.id==item.client).rate, type: getKeyByValue($store.state.user.balanceTypes.income, $store.state.clients.find(c=>c.id==item.client).name), manageTime: 1, income: 1})"
                        text>В доход
                    </v-btn>
                  </td>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card>
            <v-card-title class="pb-0">
              Финансы
              <v-btn class="ml-2" @click="$refs.edit.open({id:0, income: 0})">+ Расход</v-btn>
              <v-btn class="ml-2" @click="$refs.edit.open({id:0, income: 1})">+ Доход</v-btn>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-chip class="mx-2" label>
                      Всего
                      {{ $root.printCost(balance.total) }}
                    </v-chip>
                  </div>
                </template>
                <span>Всего</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <div v-bind="attrs" v-on="on">
                    <v-chip style="color: #3399ff" class="mr-2" label>
                      {{ $root.printCost(balance.unmanaged) }}
                    </v-chip>
                  </div>
                </template>
                <span>Не расписано</span>
              </v-tooltip>
            </v-card-title>
            <v-card-text style="flex-direction: column;margin-top: 20px">
              <v-row>
                <v-col>
                  <v-text-field label="С" type="date" v-model="filters.date[0]" @change="reloadTable(true)"/>
                </v-col>
                <v-col>
                  <v-text-field label="По" type="date" v-model="filters.date[1]" @change="reloadTable(true)"/>
                </v-col>
                <v-col>
                  <label>Не расписано</label>
                  <v-checkbox hide-details dense @change="reloadTable(true)" v-model="filters.unmanaged"/>
                </v-col>
                <v-col>
                  <label>Основание</label>
                  <v-select
                      @change="reloadTable(true)"
                      v-model="filters.types"
                      item-text="name"
                      multiple
                      item-value="value"
                      :items="$root.selectValues(types.income).concat($root.selectValues(types.cons))"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text class="pt-0">
              <v-simple-table style="width: fit-content;">
                <thead>
                <tr>
                  <th>Основание</th>
                  <th style="text-align: right">Всего</th>
                  <th style="text-align: right">Безнал/Нал</th>
                </tr>
                <tr>
                  <th style="font-weight: 800;font-size: 16px">Итого</th>
                  <th style="text-align: right">
                    <SumChip :value="info.total"></SumChip>
                  </th>
                  <th>{{ $root.printCost(info.card) }} / {{ $root.printCost(info.cash) }}
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 800;font-size: 16px">Итого доход</th>
                  <th style="text-align: right">
                    <SumChip :value="info.cashPlus+info.cardPlus"></SumChip>
                  </th>
                  <th>{{ $root.printCost(info.cardPlus) }} / {{ $root.printCost(info.cashPlus) }}
                  </th>
                </tr>
                <tr>
                  <th style="font-weight: 800;font-size: 16px">Итого расход</th>
                  <th style="text-align: right">
                    <SumChip :value="info.cashMinus+info.cardMinus"></SumChip>
                  </th>
                  <th>{{ $root.printCost(info.cardMinus) }} / {{ $root.printCost(info.cashMinus) }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr style="cursor: pointer" :key="row.type" v-for="row in info.byType"
                    v-if="row.title && (parseFloat(row.cash) ||parseFloat(row.card))"
                    :style="'background:'+(localFilter.type==row.type && localFilter.paymentType==0 ? '#aef2f6':'')"
                    @click="setLocalFilter(row.type, 0)"
                >
                  <td>{{ row.title }}</td>
                  <td style="text-align: right">
                    <SumChip :value="row.total"></SumChip>
                  </td>
                  <td> {{ $root.printCost(row.card) }} / {{ $root.printCost(row.cash) }}
                  </td>
                </tr>
                </tbody>
              </v-simple-table>
            </v-card-text>
            <v-card-text class="pt-0">
              <v-data-table
                  hover
                  :items="rows.filter((item)=>(!localFilter.type || localFilter.type==item.type) && (!localFilter.paymentType || localFilter.paymentType==item.paymentType))"
                  :headers="headers"
                  item-key="id"
                  class="cursor-pointer items"
                  :loading="loading"
                  disable-pagination
                  no-data-text="Строк нет"
                  loading-text="Загрузка..."
                  mobile-breakpoint="0">
                <template v-slot:item="{item}">
                  <tr @click="$refs.edit.open(item)">
                    <td>{{ $root.dateToRus(item.date) }}</td>
                    <td :style="'color: '+(parseFloat(item.sum)<0 ? 'red' : 'green')">{{
                        $root.printCost(item.sum)
                      }}
                    </td>
                    <td>{{ ['', 'Безнал', 'Нал'][parseInt(item.paymentType)] }}</td>
                    <td>{{ types[parseFloat(item.sum) >= 0 ? 'income' : 'cons'][parseInt(item.type)] }}</td>
                    <td>
                      {{ item.comment }}
                    </td>
                    <td>
                      <v-btn @click.stop="setManaged(item.id, 0)" color="red" small v-if="+item.managed">Отменить
                      </v-btn>
                      <v-btn @click.stop="setManaged(item.id, 1)" small v-else>Расписано</v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <Edit ref="edit"/>
    <WorktimeEdit ref="WorktimeEdit"/>
  </v-app>
</template>
<style>
.balance_info .v-list-item__content {
  display: block;
}

.balance_info .v-list-item__title {
  display: inline-block
}

.balance_info .v-list-item__subtitle {
  display: inline-block;
  margin-bottom: -3px
}
</style>
<script>
import Edit from "@/views/balance/Edit";
import SumChip from "@/components/SumChip";
import DatePeriod from "@/components/DatePeriod";
import WorktimeEdit from "@/views/balance/WorktimeEdit";

export default {
  name: "BalanceTable",
  components: {
    WorktimeEdit,
    DatePeriod,
    Edit, SumChip
  },
  data() {
    return {
      rows: [],
      types: [],
      worktime: [],
      worktimeUnmanaged: [],
      stats: {},
      info: {},
      currentMonthStart: this.$moment().startOf('month').format('YYYY-MM-DD'),
      currentMonthEnd: this.$moment().endOf('month').format('YYYY-MM-DD'),
      prevMonthStart: this.$moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
      prevMonthEnd: this.$moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD'),
      hideAll: true,
      loading: true,
      balance: {},
      localFilter: {
        paymentType: 0,
        type: 0,
      },
      filters: {
        date: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        period: [this.$moment().startOf('month').format('YYYY-MM-DD'), this.$moment().format('YYYY-MM-DD')],
        paymentType: '0',
        byItemDate: '0',
        income: '',
        types: [],
        unmanaged: 1,
      },
      headers: [
        {text: 'Дата', value: 'date'},
        {text: 'Сумма', value: 'sum'},
        {text: 'Способ оплаты', value: 'paymentType'},
        {text: 'Тип', value: 'type'},
        {text: 'Коммент', value: 'comment'},
        {text: 'Расписано', value: 'managed'},
      ],
    }
  },
  methods: {
    loadWorktime() {
      this.$store.state.server.request('worktime/get/', {period: this.filters.period}, data => {
        this.stats = data.stats;
        this.worktime = data.response;
        this.worktimeUnmanaged = data.unmanaged;
        this.$store.state.clients = data.clients;
      });
    },
    setManaged(id, managed) {
      this.$store.state.server.request('balance/managed/' + id, {managed}, () => {
        this.reloadTable(true);
      });
    },
    setLocalFilter(type, paymentType) {
      document.querySelector('body').scrollTop = this.$el.querySelector('.v-data-table.items').offsetTop
      if (this.localFilter.type === type && this.localFilter.paymentType === paymentType) {
        type = 0;
        paymentType = 0;
      }
      this.localFilter.type = type;
      this.localFilter.paymentType = paymentType;
    },
    setRows(rows) {
      this.info = {
        cardPlus: 0,
        cardMinus: 0,
        cashPlus: 0,
        cashMinus: 0,
      };
      this.info.byType = {};
      this.info.total = 0;
      this.info.cash = 0;
      this.info.card = 0;
      let byType = {};
      for (let index in this.types.income) {
        byType['_' + index] = {
          type: index,
          income: 1,
          total: 0,
          cash: 0,
          card: 0,
          title: this.types.income[index],
        }
      }
      for (let index in this.types.cons) {
        byType['_' + index] = {
          type: index,
          income: 0,
          total: 0,
          cash: 0,
          card: 0,
          title: this.types.cons[index]
        }
      }
      rows.forEach(row => {
        let sum = this.$root.round(row.sum);
        this.info.total += sum;
        byType['_' + parseInt(row.type)].total += sum;
        if (parseInt(row.paymentType) === 1) {
          byType['_' + parseInt(row.type)].card += sum;
          this.info.card += sum;
          if (sum >= 0) this.info.cardPlus += sum;
          else this.info.cardMinus += sum;
        } else {
          byType['_' + parseInt(row.type)].cash += sum;
          this.info.cash += sum;
          if (sum >= 0) {
            this.info.cashPlus += sum;
            // console.log(this.info.cashPlus, sum, row.type);
          } else this.info.cashMinus += sum;
        }
      })
      this.info.byType = Object.values(byType).sort((a, b) => {
        if (a.income !== b.income) return b.income - a.income;
        return Math.abs(b.total) - Math.abs(a.total);
      })
      console.log(this.info.byType)
      this.rows = rows;
    },
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    reloadTable(clearTable = true) {
      if (clearTable === true) {
        this.loading = true;
        console.log('save filters', {...this.filters});
        this.$root.saveData('balance', 'filters', this.filters);
      }
      this.$store.state.server.request('balance/get', this.filters, (data) => {
        this.hideAll = false;
        this.types = data.types;
        this.$store.state.user.balanceTypes = data.types;
        this.balance = data.balance;
        this.setRows(data.response);
        this.loading = false;
        if (!clearTable) setTimeout(() => {
          this.reloadTable(false)
        }, 5000)
      }, (data) => {
        if (data.error === 'wrong token') this.hideAll = true;
        if (!clearTable) setTimeout(() => {
          this.reloadTable(false)
        }, 5000)
      });
    },
    loadFilters() {
      let saved = this.$root.getData('balance', 'filters') || {};
      console.log({saved}, this.filters);
      for (let field in this.filters) {
        if (typeof saved[field] !== "undefined") this.filters[field] = saved[field];
      }
    },
  },
  mounted() {
    this.loadFilters();
    this.reloadTable(false)
    this.loadWorktime()
    this.$eventBus.$on('balanceSaved', () => {
      this.reloadTable()
      this.loadWorktime()
    });
  },
  destroyed() {
    this.$eventBus.$off('balanceSaved');
  }
}
</script>
