export class Field {
    icons = ['🤑', '🐷', '⏳', '🎒', '📘']
    width = 6;
    height = 7;
    score = 0;
    scoreByCell = 10;
    cells = [];
    cellAI = 1;

    constructor(onUpdate, cells = null, autoReduce = true) {
        this.onUpdate = onUpdate ? onUpdate : () => {
        };
        this.fill(cells);
        let i = 0;
        if (autoReduce) while (this.reduceAll()) {
            this.fill();
            i++;
            if (i > 10) {
                console.warn('too many reduceAll: ', i);
                break;
            }
        }
        this.score = 0;
    }

    getCells() {
        let cells = [];
        for (let i = 0; i < this.cells.length; ++i) cells[i] = this.cells[i].map(c => c.icon);
        return cells;
    }

    fill(cells = null, fillAfter = false) {
        for (let i = this.width - 1; i >= 0; --i) {
            if (!this.cells[i]) this.cells[i] = [];
            for (let j = 0; j < this.height; ++j) {
                if (!this.cells[i][j] || !this.cells[i][j].icon) this.cells[i][j] = {
                    icon: cells ? cells[i][j] : this.icons[Math.floor(Math.random() * this.icons.length)],
                    id: this.cellAI++
                };
            }
        }
        if (fillAfter) return this.reduceAll(fillAfter);
        this.onUpdate();
    }


    fillTest() {
        let field = [
            ['🎒', '🐷', '⏳', '🎒', '📘', '⏳', '📘'],
            ['🤑', '🤑', '📘', '🤑', '⏳', '📘', '🤑'],
            ['🤑', '📘', '📘', '⏳', '🤑', '⏳', '🐷'],
            ['🤑', '📘', '📘', '📘', '⏳', '🤑', '🎒'],
            ['🎒', '📘', '🤑', '🐷', '🤑', '⏳', '🤑'],
            ['📘', '🤑', '⏳', '📘', '⏳', '🐷', '⏳'],
        ]
        for (let i = 0; i < this.width; ++i)
            for (let j = 0; j < this.height; ++j)
                this.cells[i][j].icon = field[i][j];
        this.onUpdate();
    }

    findFigure() {
        let verticals = this.findVertical();
        let horizontals = this.findHorizontal();
        let angles = this.findAngle();
        let squares = this.findSquare();
        let figures = verticals.concat(horizontals).concat(angles).concat(squares);
        let max = 0;
        let index = -1;
        figures.forEach((figure, i) => {
            if (figure.length > max) {
                max = figure.length;
                index = i;
            }
        });
        return index >= 0 ? figures[index] : null;
    }

    reduceAll(fillAfter = false) {
        let i = 0
        while (this.reduce()) {
            i++;
            if (i > 10) {
                console.warn('too many reduce: ', i);
                break;
            }
        }
        if (i && fillAfter) this.fill(null, fillAfter);
        return i;
    }

    reduce() {
        let figure = this.findFigure();
        if (figure) this.removeFigure(figure);
        return figure;
    }

    removeFigure(figure) {
        if (figure.type === 'v') {
            for (let i = 0; i < figure.length; ++i) {
                this.removeCell(figure.from[0], figure.from[1] + figure.length - 1);
            }
        } else if (figure.type === 'h') {
            for (let i = 0; i < figure.length; ++i) {
                this.removeCell(figure.from[0] + i, figure.from[1]);
            }
        } else if (figure.type === 's') {
            this.removeCell(figure.from[0], figure.from[1]);
            this.removeCell(figure.from[0] + 1, figure.from[1]);
            this.removeCell(figure.from[0], figure.from[1] + 1);
            this.removeCell(figure.from[0] + 1, figure.from[1] + 1);
        } else if (figure.type === 'a') {
            let rowIndexes = [figure.from[0], figure.from[0] + figure.signs[1], figure.from[0] + figure.signs[1] * 2];
            let max = Math.max(...rowIndexes);
            rowIndexes.forEach(() => this.removeCell(figure.from[0], max))
            this.removeCell(figure.from[0] + figure.signs[0], figure.from[1]);
            this.removeCell(figure.from[0] + figure.signs[0] * 2, figure.from[1]);
        }
        this.score += this.scoreByCell * figure.length;
        this.onUpdate();
    }

    removeCell(i, j) {
        for (let k = j; k > 0; --k) {
            this.cells[i][k] = {...this.cells[i][k - 1]};
        }
        this.cells[i][0] = {icon: ''};
    }

    findVertical() {
        let result = [];
        let type = 'v';
        for (let i = 0; i < this.width; ++i) {
            let length = 1;
            for (let j = 1; j < this.height; ++j) {
                if (this.cells[i][j - 1].icon && this.cells[i][j - 1].icon === this.cells[i][j].icon) length++;
                else {
                    if (length >= 3) result.push({from: [i, j - length], length: length, type});
                    length = 1;
                }
            }
            if (length >= 3) result.push({from: [i, this.height - length], length: length, type});

        }
        return result;
    }

    findSquare() {
        let result = [];
        let length = 4;
        let type = 's';
        for (let i = 0; i < this.width - 1; ++i) {
            for (let j = 0; j < this.height - 1; ++j) {
                if (this.cells[i][j].icon &&
                    this.cells[i][j].icon === this.cells[i + 1][j].icon &&
                    this.cells[i][j].icon === this.cells[i][j + 1].icon &&
                    this.cells[i][j].icon === this.cells[i + 1][j + 1].icon
                ) {
                    result.push({from: [i, j], length, type});
                }
            }
        }
        return result;
    }

    findAngle() {
        let result = [];
        let length = 5;
        let type = 'a';
        for (let signI = 1; signI >= -1; signI -= 2) {
            for (let signJ = 1; signJ >= -1; signJ -= 2) {
                for (let i = 1 - signI; i < this.width - (1 + signI); ++i) {
                    for (let j = 1 - signJ; j < this.height - (1 + signJ); ++j) {
                        if (this.cells[i][j].icon &&
                            this.cells[i][j].icon === this.cells[i + signI][j].icon &&
                            this.cells[i][j].icon === this.cells[i + signI * 2][j].icon &&
                            this.cells[i][j].icon === this.cells[i][j + signJ].icon &&
                            this.cells[i][j].icon === this.cells[i][j + signJ * 2].icon
                        ) {
                            result.push({from: [i, j], signs: [signI, signJ], length, type});
                        }
                    }
                }
            }
        }
        return result;
    }

    findHorizontal() {
        let result = [];
        for (let j = 0; j < this.height; ++j) {
            let length = 1;
            for (let i = 1; i < this.width; ++i) {
                if (this.cells[i - 1][j].icon && this.cells[i - 1][j].icon === this.cells[i][j].icon) length++;
                else {
                    if (length >= 3) result.push({from: [i - length, j], length, type: 'h'});
                    length = 1;
                }
            }
            if (length >= 3) result.push({from: [this.width - length, j], length, type: 'h'});
        }
        return result;
    }
}

export class FindBestMove {
    getCells() {
        return JSON.parse(JSON.stringify(this.cells));
    }

    constructor(cells) {
        this.cells = JSON.parse(JSON.stringify(cells));
        for (let i = 0; i < this.cells.length; ++i) this.cells[i] = this.cells[i].map(c => c.icon);
    }

    run() {
        let result = {max: 0};
        for (let j = this.cells[0].length - 1; j >= 0; --j) {
            for (let i = 0; i < this.cells.length - 1; ++i) {
                let cells = this.getCells();
                let tmp = cells[i][j];
                cells[i][j] = cells[i + 1][j];
                cells[i + 1][j] = tmp;
                let field = new Field(null, cells, false);
                field.reduceAll();
                if (field.score > result.max) result = {max: field.score, cells: [[i, j], [i + 1, j]]};
                cells = this.getCells();
                tmp = cells[i][j];
                cells[i][j] = cells[i][j + 1];
                cells[i][j + 1] = tmp;
                field = new Field(null, cells);
                field.reduceAll();
                if (field.score > result.max) result = {max: field.score, cells: [[i, j], [i, j + 1]]};
            }
        }
        return result;
    }
}

