<template>
  <div>
    <select @change="load" v-model="lang" style="display: block;">
      <option v-for="lang in ['ru', 'en']" :key="lang" :value="lang">{{ lang.toUpperCase() }}</option>
    </select>
    <div style="height: 80vh;padding: 20px;display: flex;justify-content: center;">
      <div>
        <div class="row" v-for="(rule, i) in rules" :key="i" style="flex-direction: column;">
          <div style="display: flex;position: absolute;">
            <div :style="{background: colors[+rule.info[j-1]]}" v-for="j in 5" :key="j"
                 style="width: 70px;height: 70px;background:white;border: 1px solid #ddd"></div>
          </div>
          <input maxlength="5" @input="rule.word.length===5?load():''" style="z-index: 1;
    font-size: 48px;
    letter-spacing: 39px;
    text-transform: uppercase;
    width: 450px;
    outline: none;
    padding-left: 20px;" v-model="rule.word"/>
          <div style="display: flex;
    margin-top: -10px;
    z-index: 2;
    margin-bottom: 5px;">
            <div @click="rule.info[j-1]=(rule.info[j-1]+1)%3;load();" v-for="j in 5" :key="j"
                 style="width: 70px;height: 20px;background:white;border: 1px solid #ddd;cursor: pointer"></div>
          </div>
        </div>
      </div>
      <div style="margin-top: -12px">
        <div v-for="(item, i) in response" :key="i"
             style="height: 86px;min-width:300px;background: white;border-bottom: 1px solid #eee;padding: 10px;">
          <span>{{ item.count }} шт.: </span>
          <span><span @click="setWord(word, i);load();" :key="word"
                      v-for="word in item.words" style="margin-right: 5px;margin-bottom: 3px;
    padding: 1px 3px;
    background: #c7ffa6;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;">{{ word }}</span></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let rules = [];
for (let i = 0; i < 5; ++i) rules.push({word: '', info: [0, 0, 0, 0, 0]});
console.log({rules});
export default {
  name: "FiveLetters2",
  data() {
    return {
      colors: ['#eee', 'yellow', '#34dd34'],
      rules,
      lang: 'ru',
      response: [],
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    setWord(word, index) {
      console.log(this.rules, {word, index});
      this.rules[index].word = word;
    },
    load() {
      this.$store.state.server.request('fivewords/solve', {rules: this.rules, lang: this.lang}, (data) => {
        this.response = data.response;
      })
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Arial;
  background: #fff3ed;
}
</style>
