import Vue from 'vue'
import Vuex from 'vuex'
import {Server} from './server'

Vue.use(Vuex)

const state = {
  sidebarShow: localStorage.sidebarSize ? localStorage.sidebarSize : window.innerWidth < 480 ? false : 'responsive',
  updateHack: true,
  sidebarMinimize: false,
  settings: {
    notificationHideTimeout: 2000,
  },
  server: new Server(),
  user: {
    balanceFullMode: 1,
    city: {
      country: {
        currency: '₽'
      }
    }
  },
}

const mutations = {
}

export default new Vuex.Store({
  state,
  mutations,
})
