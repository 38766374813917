<template>
  <v-dialog max-width="500" data-app v-model="opened">
    <v-card>
      <v-card-title>{{ this.item.id ? 'Редактирование' : 'Создание' }}</v-card-title>
      <v-card-text>
        <v-row class="mt-2">
          <v-select
              label="Клиент"
              v-model="item.client"
              item-text="name"
              item-value="id"
              :items="$store.state.clients"
              outlined
          ></v-select>
        </v-row>
        <v-row>
          <v-text-field label="Дата" outlined type="date" v-model="item.date"/>
        </v-row>
        <v-row>
          <v-text-field
              label="Кол-во часов"
              v-model="item.hours"
              outlined
          />
        </v-row>
        <v-row>
          <v-textarea
              label="Коммент"
              rows="2"
              v-model="item.comment"
              outlined
          />
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="save" :loading="loading">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: "WorktimeEdit",
  data() {
    let today = this.$moment().format('YYYY-MM-DD');
    return {
      opened: false,
      loading: false,
      today,
      item: {}
    }
  },
  methods: {
    open(item = null) {
      this.item = item || {date: this.today};
      this.opened = true;
    },
    save() {
      this.loading = true;
      this.$store.state.server.request('worktime/' + (this.item.id ? 'update/' + this.item.id : 'create'), this.item, () => {
        this.loading = false;
        this.$eventBus.$emit('balanceSaved')
        this.opened = false;
      }, (data) => {
        this.loading = false;
      });
    },
  },
}
</script>