<template>
  <div style="height: 80vh;padding: 20px;display: flex;justify-content: center;">
    <div>
      <div class="row" v-for="(rule, i) in rules" :key="i" style="flex-direction: column;">
        <div style="display: flex;position: absolute;">
          <div :style="{background: colors[+rule.info[j-1]]}" v-for="j in 5" :key="j"
               style="width: 70px;height: 70px;background:white;border: 1px solid #ddd"></div>
        </div>
        <input maxlength="5" @input="rule.word.length===5?fillNextWord():''" style="z-index: 1;
    font-size: 48px;
    letter-spacing: 39px;
    text-transform: uppercase;
    width: 450px;
    outline: none;
    padding-left: 20px;" v-model="rule.word"/>
        <div style="display: flex;
    margin-top: -10px;
    z-index: 2;
    margin-bottom: 5px;">
          <div @click="rule.info[j-1]=(rule.info[j-1]+1)%3;$forceUpdate();" v-for="j in 5" :key="j"
               style="width: 70px;height: 20px;background:white;border: 1px solid #ddd;cursor: pointer"></div>
        </div>
      </div>
    </div>
    <div style="margin-top: -12px">
      <div v-for="i in rules.filter(r=>r.word).length+1" :key="i"
           style="height: 86px;min-width:300px;background: white;border-bottom: 1px solid #eee;padding: 10px;">
        <span>{{ nextWords(rules.slice(0, i - 1), nouns).count }} шт.: </span>
        <span><span @click="rules[i-1].word = word;fillNextWord();$forceUpdate();" :key="word"
                    v-for="word in nextWords(rules.slice(0, i-1), nouns).answer" style="margin-right: 5px;margin-bottom: 3px;
    padding: 1px 3px;
    background: #c7ffa6;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;">{{ word }}</span></span>
        <span>
        <span @click="rules[i-1].word = word.word;fillNextWord();$forceUpdate();" :key="word.word"
              v-for="word in nextWords(rules.slice(0, i-1), nouns).words" style="margin-right: 5px;margin-bottom: 3px;
    padding: 1px 3px;
    background: #eee;
    border-radius: 5px;
    display: inline-block;
    text-transform: uppercase;
    cursor: pointer;">{{ word.word }}</span>
          </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FiveLetters",
  data() {
    let rules = [];
    for (let i in [...Array(6).keys()]) rules.push({word: '', info: '00000'.split('')});
    console.log(JSON.stringify(rules));
    return {
      colors: ['#eee', 'yellow', '#34dd34'],
      // nouns: [],
      nouns2: [
        "абака",
        "аббат",
        "абвер",
        "абзац",
        "аборт",
        "абрек",
        "абрис",
        "абхаз",
        "абцуг",
        "абшид",
        "аванс",
        "авгит",
        "авгур",
        "авеню",
        "авизо",
        "авось",
        "аврал",
        "автол",
        "автор",
        "агава",
        "агама",
        "агами",
        "агент",
        "агнат",
        "агнец",
        "аграф",
        "адепт",
        "адрес",
        "адряс",
        "ажгон",
        "азарт",
        "азиат",
        "айван",
        "аймак",
        "айран",
        "айсор",
        "акажу",
        "акант",
        "акрил",
        "аксон",
        "актер",
        "актив",
        "акула",
        "акциз",
        "акция",
        "аларм",
        "алеут",
        "алиби",
        "алкаш",
        "аллея",
        "аллюр",
        "алмаз",
        "алтей",
        "алтын",
        "алчба",
        "алыча",
        "альфа",
        "амбар",
        "амбра",
        "амбре",
        "амвон",
        "амеба",
        "аминь",
        "ампер",
        "ампир",
        "ангар",
        "ангел",
        "анион",
        "анкер",
        "анонс",
        "антик",
        "антре",
        "анчар",
        "аншеф",
        "аорта",
        "апорт",
        "апрош",
        "арбуз",
        "аргон",
        "аргус",
        "ареал",
        "арека",
        "арена",
        "арест",
        "ариец",
        "аркан",
        "армия",
        "армяк",
        "архар",
        "архив",
        "аршин",
        "аскер",
        "аскет",
        "аспид",
        "астат",
        "астма",
        "астра",
        "асцит",
        "атака",
        "атлас",
        "атлет",
        "атолл",
        "аттик",
        "афера",
        "афиша",
        "ацтек",
        "аэроб",
        "аэрон",
        "бабка",
        "бабье",
        "багаж",
        "багги",
        "багет",
        "багор",
        "бадан",
        "бадья",
        "базар",
        "базис",
        "байга",
        "байда",
        "байка",
        "бакан",
        "бакен",
        "бакун",
        "балда",
        "балет",
        "балка",
        "балок",
        "балык",
        "банан",
        "банда",
        "банка",
        "барак",
        "баран",
        "бареж",
        "баржа",
        "барий",
        "барин",
        "барит",
        "барич",
        "барка",
        "бармы",
        "барон",
        "барыш",
        "баска",
        "басма",
        "баснь",
        "басня",
        "басок",
        "басон",
        "батат",
        "батог",
        "батон",
        "батут",
        "батыр",
        "бахта",
        "бахча",
        "бачок",
        "башка",
        "башня",
        "бегун",
        "бедро",
        "безик",
        "бейка",
        "бекар",
        "бекас",
        "бекон",
        "белек",
        "белец",
        "белка",
        "белок",
        "белье",
        "беляк",
        "беляш",
        "бердо",
        "берег",
        "берет",
        "бетон",
        "бивак",
        "бидон",
        "бизон",
        "билет",
        "билль",
        "бином",
        "биржа",
        "бирка",
        "бирюк",
        "бирюч",
        "бисер",
        "битва",
        "биток",
        "битум",
        "битье",
        "битюг",
        "благо",
        "блажь",
        "бланк",
        "блато",
        "блеск",
        "близь",
        "блинт",
        "блоха",
        "блуза",
        "блюдо",
        "бляха",
        "бобер",
        "бобик",
        "бобок",
        "богач",
        "бодяк",
        "божба",
        "божок",
        "бойня",
        "бокал",
        "болид",
        "бомба",
        "бонза",
        "бонмо",
        "бонна",
        "бордо",
        "борей",
        "борец",
        "боров",
        "борок",
        "борть",
        "босяк",
        "ботва",
        "ботик",
        "бочаг",
        "бочар",
        "бочка",
        "бочок",
        "браво",
        "брага",
        "брада",
        "брань",
        "брасс",
        "бремя",
        "брешь",
        "бридж",
        "брика",
        "бриль",
        "бритт",
        "бровь",
        "бронх",
        "броня",
        "брошь",
        "брыжи",
        "брыла",
        "брюки",
        "брюхо",
        "бубен",
        "бубна",
        "бубон",
        "бугай",
        "бугор",
        "будка",
        "будра",
        "будяк",
        "букан",
        "буква",
        "букет",
        "букле",
        "букля",
        "букса",
        "булат",
        "булга",
        "булка",
        "булла",
        "бурав",
        "бурак",
        "буран",
        "бурат",
        "бурда",
        "бурка",
        "бурса",
        "бурун",
        "бурят",
        "бутан",
        "бутон",
        "бутса",
        "бутуз",
        "буфер",
        "буфет",
        "бухта",
        "быдло",
        "былка",
        "былое",
        "былье",
        "бытие",
        "бытье",
        "бычок",
        "бювар",
        "бювет",
        "бюкса",
        "вабик",
        "вагон",
        "вазон",
        "вакса",
        "валах",
        "валеж",
        "валек",
        "валер",
        "валет",
        "валец",
        "валик",
        "валка",
        "валок",
        "валуй",
        "валун",
        "валух",
        "вальс",
        "ванна",
        "ванта",
        "варан",
        "варка",
        "варок",
        "варяг",
        "ватин",
        "ватка",
        "вафля",
        "вахта",
        "вдова",
        "ведро",
        "ведро",
        "ведун",
        "вежда",
        "векша",
        "велюр",
        "венгр",
        "венец",
        "веник",
        "венок",
        "вепрь",
        "верба",
        "вервь",
        "веред",
        "верес",
        "верея",
        "верки",
        "верфь",
        "верша",
        "весло",
        "весна",
        "весок",
        "весть",
        "ветвь",
        "ветер",
        "ветка",
        "ветла",
        "вечер",
        "вешка",
        "вещун",
        "взбег",
        "взвар",
        "взвод",
        "вздор",
        "вздох",
        "взлет",
        "взлом",
        "взмах",
        "взмет",
        "взмыв",
        "взнос",
        "взрез",
        "взрыв",
        "взрыд",
        "взыск",
        "видео",
        "видик",
        "видок",
        "визаж",
        "визир",
        "визит",
        "вилка",
        "вилла",
        "вилок",
        "винцо",
        "виола",
        "вираж",
        "вирус",
        "вирша",
        "виски",
        "висок",
        "вития",
        "виток",
        "витье",
        "вихор",
        "вихрь",
        "вишня",
        "вклад",
        "влага",
        "власы",
        "внука",
        "вобла",
        "вогул",
        "водка",
        "вожак",
        "вождь",
        "вожжа",
        "возик",
        "возка",
        "возня",
        "возок",
        "война",
        "вокал",
        "волан",
        "волга",
        "волна",
        "волок",
        "волос",
        "волхв",
        "вольт",
        "вопль",
        "ворог",
        "ворон",
        "ворот",
        "ворох",
        "ворье",
        "вотум",
        "вотяк",
        "вошка",
        "вояка",
        "впуск",
        "враки",
        "враль",
        "врата",
        "время",
        "вруша",
        "вспых",
        "всход",
        "втора",
        "вуаль",
        "вчера",
        "въезд",
        "выбой",
        "выбор",
        "вывал",
        "вывес",
        "вывих",
        "вывод",
        "вывоз",
        "выгар",
        "выгиб",
        "выгон",
        "выгул",
        "выдел",
        "выдох",
        "выдра",
        "выдув",
        "выезд",
        "выжиг",
        "выжим",
        "вызов",
        "выкат",
        "выкол",
        "выкос",
        "выкуп",
        "выкус",
        "вылаз",
        "вылет",
        "вылов",
        "вылом",
        "вымах",
        "вымор",
        "вынос",
        "выпад",
        "выпал",
        "выпар",
        "выпас",
        "выпор",
        "выпот",
        "вырез",
        "выруб",
        "высев",
        "вытье",
        "выход",
        "вычет",
        "вышка",
        "вьюга",
        "вязка",
        "вязок",
        "вятич",
        "вятка",
        "гавот",
        "гагат",
        "газик",
        "газок",
        "газон",
        "гайка",
        "галка",
        "галоп",
        "галун",
        "гамак",
        "гамма",
        "ганец",
        "ганза",
        "ганка",
        "гараж",
        "гарда",
        "гарем",
        "гарус",
        "гаусс",
        "гашиш",
        "гвалт",
        "гейша",
        "гелий",
        "гемма",
        "гений",
        "герма",
        "герой",
        "гетра",
        "гетто",
        "гжель",
        "гибка",
        "гидра",
        "гиена",
        "гиляк",
        "гинея",
        "гипюр",
        "гирло",
        "гитан",
        "гитов",
        "гичка",
        "глава",
        "главк",
        "гладь",
        "глина",
        "глист",
        "глубь",
        "глушь",
        "глыба",
        "глясе",
        "гнейс",
        "гнида",
        "гниль",
        "гнома",
        "гнусь",
        "гобой",
        "говор",
        "гогот",
        "годик",
        "годок",
        "голец",
        "голик",
        "голод",
        "голос",
        "голыш",
        "гольд",
        "голье",
        "гольф",
        "голяк",
        "гомон",
        "гонец",
        "гонка",
        "гонор",
        "гопак",
        "горец",
        "горка",
        "горло",
        "горно",
        "город",
        "горох",
        "горюн",
        "гость",
        "гофре",
        "граве",
        "грамм",
        "гранд",
        "грант",
        "грань",
        "графа",
        "гребь",
        "греза",
        "грена",
        "греча",
        "грива",
        "гридь",
        "гриль",
        "грипп",
        "гроза",
        "грозд",
        "гросс",
        "груда",
        "грудь",
        "грунт",
        "груша",
        "грыжа",
        "гряда",
        "грязь",
        "гуано",
        "гуашь",
        "губан",
        "губка",
        "гугня",
        "гудок",
        "гужик",
        "гузка",
        "гузно",
        "гуляш",
        "гумма",
        "гумми",
        "гумно",
        "гумус",
        "гуран",
        "гурда",
        "гурия",
        "гусак",
        "гусар",
        "гусек",
        "гусит",
        "гусли",
        "гуцул",
        "гюрза",
        "давка",
        "дадан",
        "дамба",
        "дамка",
        "дачка",
        "дверь",
        "дебет",
        "дебил",
        "дебит",
        "дебош",
        "дебри",
        "дебют",
        "девиз",
        "девка",
        "девон",
        "дедка",
        "дедок",
        "дежка",
        "деизм",
        "деист",
        "декан",
        "декор",
        "дележ",
        "делец",
        "демон",
        "демос",
        "денди",
        "денек",
        "дерби",
        "дерен",
        "дерма",
        "дерть",
        "десна",
        "десть",
        "детва",
        "детка",
        "дефис",
        "джига",
        "джинн",
        "дзета",
        "дзюдо",
        "диана",
        "диван",
        "диета",
        "дикая",
        "динар",
        "динго",
        "дичок",
        "длань",
        "длина",
        "днище",
        "добор",
        "довод",
        "догма",
        "дождь",
        "дожим",
        "дозор",
        "дойка",
        "дойна",
        "дойра",
        "докер",
        "домек",
        "домен",
        "домер",
        "домна",
        "домра",
        "донец",
        "донка",
        "донна",
        "донор",
        "донос",
        "донце",
        "донья",
        "дорка",
        "досев",
        "доска",
        "досол",
        "досуг",
        "досыл",
        "досье",
        "дофин",
        "доход",
        "дочка",
        "дошка",
        "драга",
        "драже",
        "драка",
        "драма",
        "дрань",
        "древо",
        "дрейф",
        "дрель",
        "дрема",
        "дрема",
        "дрена",
        "дробь",
        "дрова",
        "дрога",
        "дрожь",
        "дрозд",
        "дрофа",
        "друза",
        "друид",
        "дрянь",
        "дубец",
        "дубка",
        "дубль",
        "дубок",
        "дубье",
        "дувал",
        "дудак",
        "дудка",
        "дудук",
        "дужка",
        "дукат",
        "думец",
        "думка",
        "дунец",
        "дунит",
        "дунст",
        "дупло",
        "дурак",
        "дурка",
        "дурра",
        "дурро",
        "дутар",
        "дутик",
        "дутыш",
        "дутье",
        "духан",
        "душка",
        "душок",
        "дуэль",
        "дщерь",
        "дылда",
        "дымка",
        "дымок",
        "дырка",
        "дышло",
        "дюбек",
        "дюкер",
        "дюшес",
        "дятел",
        "евнух",
        "еврей",
        "егерь",
        "егоза",
        "ежиха",
        "ездка",
        "ездок",
        "елина",
        "емеля",
        "ересь",
        "ерник",
        "ерник",
        "ершик",
        "есаул",
        "ехида",
        "жажда",
        "жакан",
        "жакет",
        "жакоб",
        "жамка",
        "жарка",
        "жарок",
        "жатва",
        "жатка",
        "жевок",
        "желна",
        "желоб",
        "желть",
        "желчь",
        "желчь",
        "жених",
        "женка",
        "жеода",
        "жердь",
        "жерех",
        "жерло",
        "жесть",
        "жетон",
        "живец",
        "живот",
        "живье",
        "жизнь",
        "жилет",
        "жилец",
        "жилка",
        "жилье",
        "жинка",
        "жираф",
        "жирок",
        "житие",
        "житье",
        "жменя",
        "жнива",
        "жниво",
        "жница",
        "жокей",
        "жрица",
        "жулан",
        "жулик",
        "жулье",
        "жупан",
        "жупел",
        "журка",
        "жучка",
        "жучок",
        "забег",
        "забой",
        "забор",
        "завал",
        "завет",
        "завод",
        "завоз",
        "завуч",
        "загар",
        "загиб",
        "загон",
        "загул",
        "задел",
        "задик",
        "задок",
        "задор",
        "заеда",
        "заезд",
        "зажим",
        "зажин",
        "зажор",
        "зазор",
        "зазыв",
        "заика",
        "зайка",
        "заказ",
        "закал",
        "закат",
        "закол",
        "закон",
        "закуп",
        "закут",
        "залет",
        "залив",
        "зализ",
        "залог",
        "залом",
        "замах",
        "замер",
        "замес",
        "замет",
        "замок",
        "замор",
        "замша",
        "занос",
        "запад",
        "запал",
        "запас",
        "запах",
        "запев",
        "запой",
        "запон",
        "запор",
        "зарез",
        "зарод",
        "зарок",
        "заряд",
        "засев",
        "засов",
        "засол",
        "засор",
        "засос",
        "засыл",
        "затек",
        "затес",
        "затея",
        "заток",
        "затон",
        "затор",
        "заумь",
        "заход",
        "зацеп",
        "зачес",
        "зачет",
        "зачин",
        "защип",
        "звено",
        "звень",
        "зверь",
        "зебра",
        "зевок",
        "зелье",
        "зельц",
        "земец",
        "земля",
        "зенит",
        "зенки",
        "зерно",
        "зернь",
        "зефир",
        "зипун",
        "злато",
        "злоба",
        "злюка",
        "знамя",
        "знать",
        "зобик",
        "золка",
        "зомби",
        "зраза",
        "зубец",
        "зубик",
        "зубок",
        "зулус",
        "зурна",
        "зыбка",
        "зыбун",
        "зятек",
        "зятик",
        "иваси",
        "ивина",
        "ивняк",
        "иврит",
        "игиль",
        "игрек",
        "игрок",
        "игрун",
        "идеал",
        "идиом",
        "идиот",
        "иерей",
        "ижица",
        "избач",
        "извет",
        "извив",
        "извод",
        "извоз",
        "изгиб",
        "изгой",
        "излет",
        "излом",
        "измол",
        "измор",
        "износ",
        "изъян",
        "изыск",
        "изюбр",
        "икона",
        "икота",
        "илька",
        "ильма",
        "имидж",
        "инвар",
        "ингуш",
        "индий",
        "индус",
        "индюк",
        "инжир",
        "инока",
        "интим",
        "инула",
        "иония",
        "иприт",
        "ирбис",
        "ирмос",
        "искра",
        "искус",
        "ислам",
        "испод",
        "испуг",
        "иссоп",
        "истец",
        "истод",
        "исток",
        "исход",
        "иудей",
        "ишиас",
        "кабак",
        "кабан",
        "кабил",
        "кабул",
        "кавун",
        "кагал",
        "каган",
        "кагат",
        "кагор",
        "кадет",
        "кадий",
        "кадка",
        "кадык",
        "казак",
        "казан",
        "казах",
        "казна",
        "казнь",
        "казус",
        "кайен",
        "кайла",
        "кайло",
        "кайма",
        "кайра",
        "какао",
        "калан",
        "калач",
        "калий",
        "калиф",
        "калла",
        "калым",
        "камас",
        "камея",
        "камин",
        "камка",
        "камса",
        "камус",
        "камча",
        "камыш",
        "канал",
        "канат",
        "канва",
        "канон",
        "каноэ",
        "канун",
        "канюк",
        "капер",
        "капля",
        "капок",
        "капор",
        "капот",
        "каппа",
        "карат",
        "карга",
        "карда",
        "карел",
        "кариб",
        "карст",
        "карта",
        "каска",
        "касса",
        "каста",
        "катар",
        "катер",
        "катет",
        "катод",
        "каток",
        "катыш",
        "кацап",
        "качка",
        "кашка",
        "кашне",
        "кашпо",
        "кащей",
        "каюта",
        "квант",
        "кварк",
        "кварц",
        "квота",
        "кегль",
        "кегля",
        "кекур",
        "кельт",
        "келья",
        "кенар",
        "кенаф",
        "кепка",
        "кефир",
        "кивер",
        "кивок",
        "кизил",
        "кизяк",
        "кинза",
        "киник",
        "киоск",
        "кипер",
        "кирза",
        "кирка",
        "кирха",
        "кисет",
        "кисея",
        "киска",
        "киста",
        "кисть",
        "кифоз",
        "кичка",
        "кишка",
        "кладь",
        "клака",
        "класс",
        "клект",
        "клерк",
        "клест",
        "клеть",
        "клика",
        "клико",
        "клипс",
        "клише",
        "клоун",
        "клуня",
        "клупп",
        "клуша",
        "клюка",
        "кляча",
        "кнель",
        "кнехт",
        "книга",
        "кникс",
        "кница",
        "князь",
        "коала",
        "кобза",
        "кобра",
        "кобыз",
        "ковач",
        "ковер",
        "ковка",
        "кожан",
        "кожух",
        "кожье",
        "козел",
        "кознь",
        "козон",
        "койка",
        "койне",
        "койот",
        "кокет",
        "кокон",
        "кокор",
        "кокос",
        "колба",
        "колер",
        "колет",
        "колея",
        "колик",
        "колит",
        "колка",
        "колли",
        "колоб",
        "колок",
        "колон",
        "колос",
        "колун",
        "колча",
        "колье",
        "кольт",
        "комар",
        "комик",
        "комми",
        "комод",
        "комок",
        "конек",
        "конец",
        "коник",
        "конка",
        "конус",
        "конюх",
        "копал",
        "копач",
        "копер",
        "копир",
        "копия",
        "копка",
        "копна",
        "копра",
        "копун",
        "копыл",
        "копье",
        "корда",
        "кореш",
        "корка",
        "короб",
        "корча",
        "корчь",
        "корье",
        "коряк",
        "косач",
        "косая",
        "косец",
        "космы",
        "косок",
        "кость",
        "косье",
        "косяк",
        "котел",
        "котик",
        "коток",
        "кофей",
        "кофий",
        "кофта",
        "кочан",
        "кочет",
        "кочка",
        "кошка",
        "кошма",
        "кощей",
        "крага",
        "кража",
        "краля",
        "крапп",
        "краса",
        "кредо",
        "креол",
        "крепь",
        "кресс",
        "крест",
        "криль",
        "крица",
        "кроат",
        "кровь",
        "кроки",
        "кроль",
        "крона",
        "кросс",
        "кроха",
        "кроше",
        "круиз",
        "крупа",
        "круть",
        "круча",
        "кручь",
        "крыло",
        "крыса",
        "крыша",
        "кубик",
        "кубло",
        "кубок",
        "кудлы",
        "кудри",
        "кузен",
        "кузня",
        "кузов",
        "кукан",
        "кукиш",
        "кукла",
        "кукша",
        "кулак",
        "кулан",
        "кулек",
        "кулеш",
        "кулик",
        "кулич",
        "кулон",
        "культ",
        "кумач",
        "кумжа",
        "кумир",
        "кумык",
        "кумыс",
        "кунак",
        "купаж",
        "купец",
        "купля",
        "купол",
        "купон",
        "кураж",
        "курай",
        "курец",
        "курия",
        "курка",
        "курок",
        "курья",
        "кусок",
        "кутас",
        "кутеж",
        "куток",
        "кутум",
        "кутья",
        "кухва",
        "кухня",
        "кухта",
        "кучер",
        "кучка",
        "кушак",
        "кхмер",
        "кювет",
        "кюрий",
        "кюрин",
        "лабаз",
        "лабет",
        "лаваш",
        "лавка",
        "лавра",
        "лагун",
        "ладан",
        "ладья",
        "лазер",
        "лазея",
        "лазка",
        "лазок",
        "лайба",
        "лайда",
        "лайка",
        "лакей",
        "лампа",
        "ламут",
        "ландо",
        "ланды",
        "ланка",
        "лапка",
        "лапта",
        "лапша",
        "ларго",
        "ларек",
        "ларец",
        "ласка",
        "лассо",
        "латка",
        "латук",
        "латыш",
        "лафет",
        "лафит",
        "лачка",
        "лбина",
        "лбище",
        "левак",
        "левит",
        "левша",
        "легат",
        "легаш",
        "ледок",
        "лежак",
        "лежка",
        "лейка",
        "лемеш",
        "лемма",
        "лемур",
        "ленок",
        "лента",
        "ленто",
        "ленца",
        "лепет",
        "лепка",
        "лепра",
        "лепта",
        "лерка",
        "леска",
        "лесок",
        "лесть",
        "летка",
        "леток",
        "летун",
        "лешак",
        "леший",
        "лещик",
        "лиана",
        "ливан",
        "ливер",
        "лидер",
        "лизин",
        "лизис",
        "лизол",
        "лизун",
        "ликер",
        "лилея",
        "лилия",
        "лиман",
        "лимит",
        "лимон",
        "лимфа",
        "линек",
        "линза",
        "линия",
        "липец",
        "липка",
        "липси",
        "лирик",
        "лиска",
        "литва",
        "литер",
        "литий",
        "лития",
        "литка",
        "литье",
        "лихач",
        "лихва",
        "лицей",
        "лишай",
        "лишек",
        "лобан",
        "лобби",
        "лобик",
        "лобок",
        "ловец",
        "ловля",
        "логик",
        "лодка",
        "ложка",
        "ложок",
        "локон",
        "ломик",
        "ломка",
        "лонжа",
        "лопух",
        "лоток",
        "лотос",
        "лохмы",
        "лоция",
        "лошак",
        "лубок",
        "лужок",
        "лузга",
        "лунит",
        "лунка",
        "лупка",
        "лучик",
        "лучок",
        "лыжня",
        "лысун",
        "лытка",
        "лычко",
        "льяло",
        "любка",
        "люнет",
        "люпин",
        "люпус",
        "люрик",
        "лютик",
        "лютня",
        "люффа",
        "лючок",
        "лягва",
        "ляжка",
        "лямка",
        "ляпис",
        "лярва",
        "ляссе",
        "ляшка",
        "магик",
        "магия",
        "магма",
        "мадам",
        "маета",
        "мажор",
        "мазар",
        "мазер",
        "мазик",
        "мазка",
        "мазло",
        "мазня",
        "мазок",
        "мазур",
        "мазут",
        "майер",
        "майка",
        "майна",
        "майор",
        "макао",
        "макет",
        "макса",
        "макси",
        "малек",
        "малец",
        "малик",
        "малка",
        "малый",
        "малыш",
        "малье",
        "маляр",
        "маман",
        "мамба",
        "мамбо",
        "мамка",
        "манго",
        "манеж",
        "манер",
        "мание",
        "мания",
        "манка",
        "манко",
        "манна",
        "манок",
        "манси",
        "манто",
        "манул",
        "маори",
        "марал",
        "маран",
        "марго",
        "марка",
        "марля",
        "маска",
        "масло",
        "масон",
        "масса",
        "масть",
        "матка",
        "матюг",
        "матюк",
        "мафия",
        "махан",
        "махра",
        "мачта",
        "медик",
        "медок",
        "медяк",
        "мезга",
        "мезон",
        "мекка",
        "мелок",
        "мелос",
        "менка",
        "мерин",
        "мерка",
        "месса",
        "место",
        "месть",
        "месье",
        "месяц",
        "метан",
        "метил",
        "метис",
        "метка",
        "метка",
        "метла",
        "метод",
        "метол",
        "метро",
        "меццо",
        "мечта",
        "мешок",
        "мигач",
        "мидия",
        "мизер",
        "микст",
        "милка",
        "милок",
        "мильт",
        "мимик",
        "минер",
        "минор",
        "минус",
        "миома",
        "мираб",
        "мираж",
        "мирза",
        "мирок",
        "мирон",
        "мирра",
        "мирта",
        "миска",
        "митоз",
        "митра",
        "мишка",
        "мишук",
        "млеко",
        "могар",
        "могол",
        "модий",
        "модус",
        "мойва",
        "мойка",
        "мойра",
        "мокко",
        "мокой",
        "мокша",
        "молва",
        "молвь",
        "молка",
        "молот",
        "молох",
        "молян",
        "моляр",
        "монах",
        "мопед",
        "морда",
        "мороз",
        "морок",
        "морцо",
        "моряк",
        "мосол",
        "мосье",
        "мотет",
        "мотив",
        "мотка",
        "мотня",
        "моток",
        "мотор",
        "мотто",
        "мохер",
        "мохна",
        "мохны",
        "мочка",
        "мошка",
        "мошна",
        "мразь",
        "муаре",
        "мужик",
        "музга",
        "музей",
        "мулат",
        "мулла",
        "муляж",
        "мумие",
        "мумия",
        "мураш",
        "мурза",
        "мурин",
        "мурло",
        "мурья",
        "мусор",
        "мутон",
        "муфта",
        "муфти",
        "муцин",
        "мучка",
        "мушар",
        "мушка",
        "мымра",
        "мысик",
        "мысль",
        "мысок",
        "мытье",
        "мычка",
        "мышей",
        "мышка",
        "мышца",
        "мэрия",
        "мюзет",
        "мюрид",
        "мякиш",
        "мялка",
        "мямля",
        "мянда",
        "мясцо",
        "мятеж",
        "мятье",
        "набат",
        "набег",
        "набоб",
        "набор",
        "навал",
        "навар",
        "навес",
        "навет",
        "навис",
        "навоз",
        "навой",
        "навык",
        "наган",
        "нагар",
        "нагиб",
        "нагон",
        "нагул",
        "надел",
        "надир",
        "надой",
        "надув",
        "наезд",
        "нажиг",
        "нажим",
        "нажин",
        "назем",
        "наказ",
        "накал",
        "накат",
        "накол",
        "накра",
        "налеп",
        "налет",
        "налив",
        "налим",
        "налог",
        "налой",
        "намаз",
        "намек",
        "намет",
        "намин",
        "намол",
        "намыв",
        "нанду",
        "нанка",
        "нанос",
        "напев",
        "напой",
        "напор",
        "нарез",
        "народ",
        "нарта",
        "нарыв",
        "наряд",
        "насад",
        "насос",
        "насып",
        "натек",
        "натес",
        "натяг",
        "наука",
        "нафта",
        "нахал",
        "наход",
        "нация",
        "начес",
        "начет",
        "начин",
        "наяда",
        "невер",
        "невод",
        "негус",
        "недра",
        "недуг",
        "немая",
        "немец",
        "немка",
        "ненец",
        "ненка",
        "нерка",
        "нерпа",
        "нетяг",
        "нефть",
        "нечет",
        "нивка",
        "низка",
        "низок",
        "никто",
        "нилот",
        "нимфа",
        "нитка",
        "ничто",
        "ничья",
        "нищая",
        "новик",
        "новое",
        "ножик",
        "ножка",
        "ножны",
        "нойон",
        "нолик",
        "номад",
        "номер",
        "нонет",
        "нория",
        "норка",
        "норма",
        "норов",
        "носач",
        "носик",
        "носка",
        "носок",
        "нотис",
        "нотка",
        "ночка",
        "нудга",
        "нужда",
        "нукер",
        "нулик",
        "нутро",
        "нырец",
        "нырок",
        "нытик",
        "нытье",
        "нюанс",
        "оазис",
        "обвал",
        "обвес",
        "обвод",
        "обвоз",
        "обгон",
        "обдел",
        "обдир",
        "обдув",
        "обжиг",
        "обжим",
        "обжин",
        "обжог",
        "обзор",
        "обида",
        "обкат",
        "обком",
        "обкос",
        "облет",
        "облик",
        "облов",
        "облог",
        "облом",
        "обман",
        "обмен",
        "обмер",
        "обмет",
        "обмин",
        "обмол",
        "обмыв",
        "обнос",
        "ободь",
        "образ",
        "обрат",
        "обрез",
        "оброк",
        "обруб",
        "обруч",
        "обрыв",
        "обряд",
        "обсев",
        "обувь",
        "обуза",
        "обход",
        "обчет",
        "общее",
        "объем",
        "обыск",
        "овраг",
        "овсец",
        "овсюг",
        "овчар",
        "огонь",
        "огрех",
        "одежа",
        "одурь",
        "ожина",
        "озеро",
        "озимь",
        "озноб",
        "ойрот",
        "океан",
        "окись",
        "окиян",
        "оклад",
        "оклик",
        "окоем",
        "окрас",
        "окрик",
        "окрол",
        "округ",
        "октет",
        "окунь",
        "олеин",
        "олень",
        "олеум",
        "олива",
        "олимп",
        "олифа",
        "олово",
        "ольха",
        "омега",
        "омела",
        "омлет",
        "омуль",
        "онагр",
        "оникс",
        "онуча",
        "опала",
        "опара",
        "опека",
        "опера",
        "опись",
        "опиум",
        "оплот",
        "оплыв",
        "опоек",
        "опока",
        "опора",
        "опрос",
        "оптик",
        "орава",
        "орала",
        "орало",
        "орарь",
        "орган",
        "оргия",
        "орден",
        "ордер",
        "ореол",
        "оржад",
        "оркан",
        "орлан",
        "орлец",
        "орлик",
        "орляк",
        "оршад",
        "осада",
        "осень",
        "осетр",
        "осина",
        "оскал",
        "ослик",
        "осляк",
        "осман",
        "осмий",
        "осмол",
        "осмос",
        "особа",
        "особь",
        "осоед",
        "осока",
        "остит",
        "остов",
        "остюк",
        "остяк",
        "осыпь",
        "отава",
        "отара",
        "отбив",
        "отбой",
        "отбор",
        "отвал",
        "отвар",
        "отвес",
        "ответ",
        "отвод",
        "отвоз",
        "отгиб",
        "отгон",
        "отгул",
        "отдел",
        "отдух",
        "отдых",
        "отель",
        "отжиг",
        "отжим",
        "отзол",
        "отзыв",
        "отказ",
        "откат",
        "откол",
        "откос",
        "откуп",
        "откус",
        "отлет",
        "отлив",
        "отлов",
        "отлог",
        "отлуп",
        "отмах",
        "отмол",
        "относ",
        "отпад",
        "отпал",
        "отпор",
        "отрез",
        "отрог",
        "отрок",
        "отруб",
        "отрыв",
        "отряд",
        "отсев",
        "отсек",
        "отсос",
        "отток",
        "отход",
        "отцеп",
        "отчал",
        "отчет",
        "отчим",
        "отъем",
        "офеня",
        "офорт",
        "офсет",
        "охват",
        "охота",
        "очерк",
        "ошеек",
        "ошкуй",
        "ощупь",
        "падеж",
        "падеж",
        "падог",
        "падуб",
        "падун",
        "пайва",
        "пайза",
        "пайка",
        "пакет",
        "пакля",
        "палас",
        "палач",
        "палаш",
        "палех",
        "палец",
        "палея",
        "палия",
        "палка",
        "палуб",
        "пампа",
        "панаш",
        "панер",
        "панна",
        "панно",
        "панты",
        "панье",
        "панья",
        "папах",
        "папеж",
        "папка",
        "парад",
        "параф",
        "парез",
        "парик",
        "пария",
        "парка",
        "парня",
        "парок",
        "паром",
        "парта",
        "парун",
        "парус",
        "парча",
        "парша",
        "пасма",
        "пасмо",
        "паста",
        "пасть",
        "пасха",
        "пасюк",
        "патан",
        "патер",
        "патио",
        "патлы",
        "патуа",
        "пауза",
        "пафос",
        "пахви",
        "пахвы",
        "пахит",
        "пахта",
        "пацан",
        "пачка",
        "пашня",
        "паяло",
        "певец",
        "певун",
        "пегаш",
        "пекан",
        "пекло",
        "пемза",
        "пенал",
        "пенек",
        "пение",
        "пенис",
        "пенка",
        "пенни",
        "пенье",
        "пенье",
        "пепел",
        "пепси",
        "перга",
        "перед",
        "перец",
        "перка",
        "перло",
        "перси",
        "перст",
        "перье",
        "песец",
        "песик",
        "песнь",
        "песня",
        "песок",
        "петит",
        "петля",
        "петух",
        "печка",
        "пешец",
        "пешка",
        "пешня",
        "пещер",
        "пещур",
        "пиала",
        "пиано",
        "пивко",
        "пигус",
        "пижма",
        "пижон",
        "пиита",
        "пикан",
        "пикап",
        "пикер",
        "пикет",
        "пикон",
        "пикша",
        "пилав",
        "пилат",
        "пилка",
        "пилон",
        "пилот",
        "пиния",
        "пинна",
        "пинок",
        "пинта",
        "пипка",
        "пират",
        "пирит",
        "пирке",
        "пирог",
        "пирок",
        "пироп",
        "писец",
        "питок",
        "питон",
        "питье",
        "пифос",
        "пихта",
        "пицца",
        "пищик",
        "плавь",
        "пламя",
        "пласт",
        "плата",
        "плато",
        "плаун",
        "плаха",
        "плебс",
        "плева",
        "плеер",
        "племя",
        "плена",
        "плеск",
        "плесо",
        "плеть",
        "плечо",
        "плешь",
        "плита",
        "плица",
        "плоть",
        "плохо",
        "плюха",
        "пнище",
        "побег",
        "побои",
        "повал",
        "повар",
        "повет",
        "повод",
        "повой",
        "погиб",
        "погон",
        "подий",
        "подог",
        "подой",
        "подол",
        "поедь",
        "поезд",
        "пожар",
        "пожня",
        "пожог",
        "позем",
        "позер",
        "позор",
        "позыв",
        "позык",
        "пойка",
        "пойло",
        "пойма",
        "поиск",
        "показ",
        "покат",
        "покер",
        "покой",
        "покос",
        "покус",
        "полба",
        "полет",
        "полив",
        "полип",
        "полис",
        "полка",
        "полог",
        "полоз",
        "полой",
        "полок",
        "полом",
        "полон",
        "полюс",
        "поляк",
        "поляш",
        "помет",
        "помин",
        "помои",
        "помол",
        "помор",
        "помпа",
        "понос",
        "пончо",
        "понюх",
        "попас",
        "попик",
        "попка",
        "попса",
        "порез",
        "порей",
        "порка",
        "порог",
        "порок",
        "порох",
        "порто",
        "поруб",
        "порча",
        "порыв",
        "посад",
        "посев",
        "посол",
        "посох",
        "посул",
        "посыл",
        "поташ",
        "потек",
        "потир",
        "поток",
        "потоп",
        "потяг",
        "поход",
        "почва",
        "почет",
        "почин",
        "почка",
        "почта",
        "пошиб",
        "пошив",
        "поэма",
        "право",
        "праща",
        "прель",
        "пресс",
        "прием",
        "прима",
        "принц",
        "приор",
        "причт",
        "приют",
        "проба",
        "проем",
        "проза",
        "пропс",
        "просо",
        "проух",
        "профи",
        "прусс",
        "прыск",
        "прыть",
        "прядь",
        "пряжа",
        "пряха",
        "псарь",
        "псина",
        "псица",
        "псише",
        "птаха",
        "птица",
        "пуант",
        "пугач",
        "пудик",
        "пудра",
        "пузан",
        "пукля",
        "пульс",
        "пульт",
        "пунец",
        "пункт",
        "пупок",
        "пурга",
        "пурин",
        "пурка",
        "пусто",
        "путец",
        "путик",
        "пуфик",
        "пучка",
        "пучок",
        "пушка",
        "пушок",
        "пчела",
        "пшено",
        "пыжик",
        "пырей",
        "пытка",
        "пышка",
        "пьеза",
        "пьеса",
        "пялка",
        "пясть",
        "пятак",
        "пятка",
        "пятно",
        "пяток",
        "радар",
        "раджа",
        "радий",
        "радио",
        "радон",
        "разик",
        "разок",
        "разор",
        "разум",
        "раина",
        "район",
        "ракия",
        "ракша",
        "рамка",
        "рампа",
        "ранет",
        "ранец",
        "ранка",
        "растр",
        "ратай",
        "ратин",
        "раунд",
        "рафия",
        "рахит",
        "рацея",
        "рация",
        "рачок",
        "рвань",
        "рвота",
        "рдест",
        "ребаб",
        "ребро",
        "ребус",
        "ревун",
        "регби",
        "редан",
        "редис",
        "редут",
        "режим",
        "резак",
        "резец",
        "резка",
        "резня",
        "резон",
        "рейка",
        "рельс",
        "ремез",
        "ремиз",
        "ренет",
        "рений",
        "рента",
        "репей",
        "репер",
        "репка",
        "репье",
        "речка",
        "решка",
        "ржавь",
        "ржица",
        "ржище",
        "ризки",
        "рикша",
        "ринит",
        "рипус",
        "риска",
        "ритор",
        "рифма",
        "ришта",
        "робот",
        "ровик",
        "ровня",
        "рогач",
        "рогоз",
        "родий",
        "родич",
        "родня",
        "рожак",
        "рожок",
        "рожон",
        "розан",
        "розга",
        "рознь",
        "ройба",
        "ройка",
        "рокер",
        "рокот",
        "ролик",
        "роман",
        "ромец",
        "ромок",
        "ромша",
        "рондо",
        "ронжа",
        "ропак",
        "ропот",
        "ростр",
        "ротик",
        "роток",
        "ротор",
        "рохля",
        "рояль",
        "ртище",
        "ртуть",
        "рубеж",
        "рубец",
        "рубин",
        "рубка",
        "рубль",
        "ругня",
        "рудяк",
        "ружье",
        "руина",
        "рукав",
        "рулет",
        "рулон",
        "румба",
        "румын",
        "рупия",
        "рупор",
        "русак",
        "русин",
        "русло",
        "рутил",
        "ручей",
        "ручка",
        "рыбак",
        "рыбец",
        "рыбка",
        "рывок",
        "рыжак",
        "рыжик",
        "рында",
        "рынок",
        "рысак",
        "рысца",
        "рытье",
        "рычаг",
        "рэкет",
        "рюмка",
        "рюшка",
        "рябец",
        "рябик",
        "рябок",
        "рядно",
        "рядок",
        "ряска",
        "рясна",
        "саами",
        "сабан",
        "сабеи",
        "сабей",
        "сабза",
        "сабля",
        "сабур",
        "саван",
        "сагиб",
        "садик",
        "садно",
        "садок",
        "сазан",
        "сайга",
        "сайда",
        "сайка",
        "сайра",
        "саква",
        "сакля",
        "салат",
        "салма",
        "салол",
        "салон",
        "салоп",
        "салют",
        "саман",
        "самба",
        "самбо",
        "самец",
        "самка",
        "самум",
        "санки",
        "сапер",
        "сапка",
        "сапог",
        "сапун",
        "сарай",
        "саржа",
        "сарыч",
        "сатин",
        "сатир",
        "сауна",
        "сахар",
        "сачок",
        "сбора",
        "сброд",
        "сброс",
        "сбруя",
        "свара",
        "сваха",
        "свеча",
        "свиль",
        "свист",
        "свита",
        "свора",
        "свояк",
        "связа",
        "связь",
        "сглаз",
        "сдача",
        "сдвиг",
        "сдоба",
        "сеанс",
        "севба",
        "север",
        "севок",
        "седло",
        "седок",
        "сеево",
        "сезам",
        "сезон",
        "сейид",
        "сейша",
        "секач",
        "секта",
        "селен",
        "селин",
        "семга",
        "семит",
        "семья",
        "сенат",
        "сенцо",
        "сенцы",
        "сепия",
        "серия",
        "серка",
        "серко",
        "серна",
        "серсо",
        "серум",
        "сетка",
        "сеунч",
        "сечка",
        "сиаль",
        "сивая",
        "сивер",
        "сивка",
        "сивко",
        "сивуч",
        "сигма",
        "сидор",
        "сиена",
        "сижок",
        "сизяк",
        "силач",
        "силка",
        "силок",
        "силон",
        "силос",
        "силур",
        "сильф",
        "симка",
        "синап",
        "синец",
        "синод",
        "синус",
        "синяк",
        "синяя",
        "сипай",
        "сирен",
        "сирин",
        "сироп",
        "систр",
        "ситар",
        "ситец",
        "ситро",
        "ситце",
        "сифон",
        "скала",
        "скало",
        "скань",
        "скарб",
        "скарн",
        "скаут",
        "скачь",
        "сквер",
        "скейт",
        "скена",
        "скепт",
        "скетч",
        "скирд",
        "склад",
        "склеп",
        "склиз",
        "склон",
        "скоба",
        "скопа",
        "скора",
        "скорм",
        "скотч",
        "скрап",
        "скреп",
        "скрип",
        "скуди",
        "скудо",
        "скука",
        "скула",
        "скунс",
        "слава",
        "слайд",
        "слань",
        "слега",
        "слеза",
        "сленг",
        "слива",
        "слизь",
        "слово",
        "слуга",
        "слуда",
        "слука",
        "слюда",
        "слюна",
        "смазь",
        "смена",
        "смерд",
        "смерч",
        "смесь",
        "смета",
        "смола",
        "смоль",
        "смотр",
        "смрад",
        "смута",
        "смуть",
        "смысл",
        "снедь",
        "сноха",
        "сныть",
        "собор",
        "совет",
        "совик",
        "совка",
        "совок",
        "согды",
        "согра",
        "содом",
        "созыв",
        "сойка",
        "сойма",
        "сойот",
        "сокол",
        "солея",
        "солка",
        "солод",
        "соляр",
        "сомик",
        "сомье",
        "сонет",
        "сопка",
        "сопло",
        "сопля",
        "сопун",
        "сорго",
        "сорит",
        "сорок",
        "сором",
        "сорус",
        "сосед",
        "сосец",
        "соска",
        "сосна",
        "сосок",
        "сосуд",
        "сосун",
        "сотая",
        "сотка",
        "сотня",
        "софит",
        "сохач",
        "сошка",
        "спазм",
        "спесь",
        "спина",
        "спирт",
        "спица",
        "сплав",
        "сплин",
        "спора",
        "спорт",
        "спрей",
        "спрос",
        "спрут",
        "спурт",
        "спуск",
        "среда",
        "ссора",
        "ссуда",
        "стадо",
        "сталь",
        "станс",
        "старт",
        "старь",
        "стать",
        "ствол",
        "створ",
        "стега",
        "стезя",
        "стека",
        "стела",
        "стена",
        "стенд",
        "стень",
        "степс",
        "степь",
        "стило",
        "стиль",
        "стоик",
        "столб",
        "столп",
        "стопа",
        "стояк",
        "страж",
        "страз",
        "страх",
        "стриж",
        "строй",
        "строп",
        "струг",
        "струп",
        "струя",
        "стужа",
        "ступь",
        "стыдь",
        "стынь",
        "суаре",
        "судак",
        "судно",
        "судок",
        "судья",
        "суета",
        "сукно",
        "сулея",
        "сулой",
        "сумка",
        "сумма",
        "супер",
        "супец",
        "супин",
        "суржа",
        "сурик",
        "сурна",
        "сурок",
        "сусак",
        "сусек",
        "сусло",
        "сутаж",
        "сутки",
        "суфле",
        "сучок",
        "сучье",
        "сушка",
        "сфера",
        "схема",
        "схима",
        "сцена",
        "съезд",
        "сынок",
        "сыпец",
        "сырец",
        "сырок",
        "сырть",
        "сырца",
        "сырье",
        "сычуг",
        "сыщик",
        "сюжет",
        "сюита",
        "сябер",
        "сяжок",
        "табак",
        "табло",
        "табор",
        "табун",
        "тавот",
        "тавро",
        "таган",
        "тазик",
        "тайга",
        "тайна",
        "такса",
        "такси",
        "такыр",
        "талер",
        "талес",
        "талик",
        "талия",
        "талон",
        "талыш",
        "тальк",
        "тамга",
        "танго",
        "танец",
        "танин",
        "танок",
        "тапер",
        "тапир",
        "тапка",
        "таран",
        "тариф",
        "тарын",
        "таска",
        "татка",
        "тафта",
        "тафья",
        "тахта",
        "тацет",
        "тачка",
        "ташка",
        "тварь",
        "твист",
        "театр",
        "тезис",
        "тезка",
        "теизм",
        "теист",
        "текст",
        "телец",
        "телик",
        "телка",
        "телок",
        "тембр",
        "темка",
        "тенек",
        "тенор",
        "тепло",
        "терем",
        "терка",
        "термы",
        "терно",
        "тесак",
        "теска",
        "тесло",
        "тесто",
        "тесть",
        "тетка",
        "теург",
        "течка",
        "тешка",
        "тиара",
        "тигра",
        "тимол",
        "типаж",
        "типец",
        "типик",
        "типун",
        "тираж",
        "тиран",
        "тиски",
        "титан",
        "титло",
        "титул",
        "тифон",
        "тифус",
        "ткань",
        "тлень",
        "товар",
        "тодес",
        "тойон",
        "токай",
        "толай",
        "толпа",
        "толща",
        "томан",
        "томат",
        "томик",
        "тондо",
        "тоник",
        "тонна",
        "тонус",
        "топаз",
        "топик",
        "топка",
        "топор",
        "топот",
        "торба",
        "торец",
        "торий",
        "торит",
        "торос",
        "тоска",
        "тотем",
        "тохар",
        "точка",
        "трава",
        "тракт",
        "транс",
        "транш",
        "трапп",
        "трасс",
        "трата",
        "траур",
        "треба",
        "трель",
        "треск",
        "трест",
        "треть",
        "треух",
        "трефа",
        "триас",
        "триба",
        "триер",
        "трико",
        "триод",
        "тромб",
        "тромп",
        "тропа",
        "труба",
        "труха",
        "трюмо",
        "туаль",
        "тубус",
        "тугай",
        "тугун",
        "тузик",
        "тукан",
        "тулес",
        "тулий",
        "тулуз",
        "тулук",
        "тулун",
        "тулуп",
        "тулья",
        "тумак",
        "туман",
        "тумба",
        "тунец",
        "тупей",
        "тупец",
        "тупик",
        "турач",
        "турка",
        "турне",
        "турок",
        "тутор",
        "тутти",
        "туфля",
        "тучка",
        "тушка",
        "тщета",
        "тыква",
        "тынок",
        "тырло",
        "тырса",
        "тычок",
        "тюбик",
        "тюник",
        "тюрбе",
        "тюрбо",
        "тюрик",
        "тютюн",
        "тюфяк",
        "тючок",
        "тябло",
        "тягач",
        "тягло",
        "тяжба",
        "тяпка",
        "уазик",
        "уброд",
        "убрус",
        "убыль",
        "увраж",
        "угода",
        "уголь",
        "угорь",
        "угрев",
        "удаль",
        "удача",
        "удерж",
        "удило",
        "удэге",
        "удэхе",
        "узбек",
        "узина",
        "узник",
        "уйгур",
        "уклад",
        "уклея",
        "уклон",
        "укроп",
        "укрюк",
        "уксус",
        "улика",
        "улита",
        "улица",
        "улыба",
        "умбра",
        "умище",
        "умник",
        "умора",
        "униат",
        "уника",
        "унион",
        "унтер",
        "унция",
        "упрек",
        "упырь",
        "ураза",
        "урема",
        "урина",
        "урман",
        "усина",
        "усище",
        "ускок",
        "успех",
        "устав",
        "устой",
        "уступ",
        "устье",
        "утеря",
        "утеха",
        "утиль",
        "утица",
        "ухарь",
        "ухват",
        "ухожь",
        "учеба",
        "ушица",
        "ушкан",
        "ушкуй",
        "ушник",
        "ущерб",
        "фабра",
        "фавор",
        "фавус",
        "фагот",
        "фадом",
        "фазан",
        "фазис",
        "факел",
        "факир",
        "фалда",
        "фальц",
        "фанат",
        "фарад",
        "фарси",
        "фасад",
        "фасет",
        "фаска",
        "фасон",
        "фатум",
        "фатюй",
        "фауна",
        "фация",
        "фаянс",
        "фелон",
        "фенол",
        "ферзь",
        "ферма",
        "феска",
        "фетиш",
        "фетюк",
        "фиакр",
        "фибра",
        "фижмы",
        "физик",
        "физия",
        "фикус",
        "филей",
        "филер",
        "филин",
        "фильм",
        "фильц",
        "фимоз",
        "финал",
        "финик",
        "финиш",
        "финка",
        "финна",
        "фиорд",
        "фирма",
        "фитин",
        "фишка",
        "фланг",
        "фланк",
        "флейт",
        "флейц",
        "флешь",
        "флинт",
        "флирт",
        "флокс",
        "флора",
        "флюид",
        "фляга",
        "фобия",
        "фокус",
        "фолио",
        "фомка",
        "форма",
        "форте",
        "форум",
        "фоска",
        "фотон",
        "фофан",
        "фраер",
        "фраза",
        "франк",
        "франт",
        "фрахт",
        "фреза",
        "френч",
        "фронт",
        "фрукт",
        "фугас",
        "фужер",
        "фузея",
        "фукус",
        "фуляр",
        "фураж",
        "фурия",
        "фурма",
        "фурор",
        "футер",
        "футор",
        "фуфло",
        "фуэте",
        "фьорд",
        "фюрер",
        "хабар",
        "хаджи",
        "хадис",
        "хазар",
        "хайло",
        "хакас",
        "хакер",
        "халат",
        "халва",
        "халда",
        "халиф",
        "хамит",
        "хамка",
        "хамса",
        "хамье",
        "ханжа",
        "ханша",
        "хапун",
        "харчо",
        "хасид",
        "хатка",
        "хашиш",
        "хвала",
        "хворь",
        "хвост",
        "хедер",
        "хедив",
        "херес",
        "херик",
        "хиазм",
        "хиляк",
        "химик",
        "химия",
        "хинди",
        "хинду",
        "хинин",
        "хиппи",
        "хитин",
        "хитон",
        "хлест",
        "хлупь",
        "хлыст",
        "хлюст",
        "хлябь",
        "хмара",
        "хмарь",
        "хмель",
        "хмурь",
        "хобби",
        "хобот",
        "ходок",
        "ходун",
        "холка",
        "холод",
        "холоп",
        "холст",
        "холуй",
        "хомут",
        "хомяк",
        "хорал",
        "хорда",
        "хорей",
        "хорек",
        "хорея",
        "хохма",
        "хохол",
        "хохот",
        "хруст",
        "хряпа",
        "хряск",
        "хряст",
        "хумус",
        "хунта",
        "хурал",
        "хурма",
        "хутор",
        "цадик",
        "цанга",
        "цапка",
        "цапля",
        "цапун",
        "цапфа",
        "царек",
        "цацка",
        "цевка",
        "цевье",
        "цедра",
        "цезий",
        "целик",
        "целое",
        "центр",
        "цехин",
        "цикля",
        "цинга",
        "циник",
        "циста",
        "цитра",
        "цифра",
        "цокот",
        "цукат",
        "цуцик",
        "цыбик",
        "цыган",
        "цыпка",
        "чабан",
        "чабер",
        "чабер",
        "чадра",
        "чайка",
        "чакан",
        "чалка",
        "чалма",
        "чарка",
        "часик",
        "часок",
        "часть",
        "чашка",
        "чебак",
        "чебот",
        "чекан",
        "челка",
        "чемер",
        "чепан",
        "чепец",
        "черва",
        "червь",
        "черед",
        "через",
        "черен",
        "череп",
        "чернь",
        "черта",
        "ческа",
        "честь",
        "четка",
        "чехол",
        "чечен",
        "чечет",
        "чешка",
        "чешуя",
        "чибис",
        "чижик",
        "чилим",
        "чинар",
        "чинка",
        "чирей",
        "чирик",
        "чирок",
        "чирус",
        "чиряк",
        "число",
        "читка",
        "чомга",
        "чрево",
        "чреда",
        "чтиво",
        "чтица",
        "чубик",
        "чубук",
        "чувал",
        "чуваш",
        "чувяк",
        "чугун",
        "чудак",
        "чудик",
        "чужак",
        "чужая",
        "чужое",
        "чуйка",
        "чукча",
        "чулан",
        "чулок",
        "чумак",
        "чурек",
        "чурка",
        "чутье",
        "чухна",
        "чушка",
        "шабат",
        "шабер",
        "шабер",
        "шабли",
        "шабот",
        "шабур",
        "шавка",
        "шажок",
        "шайба",
        "шайка",
        "шакал",
        "шалаш",
        "шалон",
        "шалун",
        "шаман",
        "шамот",
        "шанец",
        "шанкр",
        "шапка",
        "шарик",
        "шасла",
        "шассе",
        "шасси",
        "шатен",
        "шатер",
        "шатия",
        "шатун",
        "шафер",
        "шахта",
        "шашка",
        "шваль",
        "шванк",
        "шверт",
        "шевро",
        "шейка",
        "шелеп",
        "шелом",
        "шельф",
        "шемая",
        "шепот",
        "шериф",
        "шибер",
        "шизик",
        "шиизм",
        "шимми",
        "шинок",
        "шипок",
        "шипун",
        "ширма",
        "шитво",
        "шитик",
        "шитье",
        "шифер",
        "шифон",
        "шихта",
        "шишак",
        "шишка",
        "шкала",
        "шквал",
        "шкерт",
        "шкода",
        "школа",
        "шкура",
        "шланг",
        "шлейф",
        "шлица",
        "шлюха",
        "шляпа",
        "шмель",
        "шмоль",
        "шнапс",
        "шнека",
        "шнява",
        "шняка",
        "шорня",
        "шорох",
        "шоссе",
        "шофер",
        "шпага",
        "шпала",
        "шпана",
        "шпиль",
        "шпион",
        "шпора",
        "шприц",
        "шпрот",
        "шпуля",
        "шпунт",
        "шпынь",
        "шримс",
        "шрифт",
        "штамб",
        "штамм",
        "штамп",
        "штаны",
        "штейн",
        "штиль",
        "штифт",
        "штора",
        "шторм",
        "штосс",
        "штраб",
        "штраф",
        "штрек",
        "штрих",
        "штука",
        "штурм",
        "штыка",
        "штырь",
        "шубка",
        "шугай",
        "шуйца",
        "шулер",
        "шумок",
        "шурин",
        "шурпа",
        "шуруп",
        "шуряк",
        "шутка",
        "шушун",
        "шхуна",
        "щебет",
        "щегол",
        "щекот",
        "щелка",
        "щелок",
        "щенок",
        "щепка",
        "щепье",
        "щерба",
        "щетка",
        "щечка",
        "щипец",
        "щипка",
        "щипок",
        "щитик",
        "щиток",
        "щупик",
        "щурка",
        "щучка",
        "эвенк",
        "эгида",
        "эгрет",
        "эдикт",
        "экзот",
        "эклер",
        "экран",
        "элита",
        "эллин",
        "эмаль",
        "эммер",
        "энзим",
        "эолит",
        "эпика",
        "эпонж",
        "эпоха",
        "эрзац",
        "эркер",
        "эскиз",
        "эспри",
        "эстет",
        "этика",
        "этнос",
        "эфиоп",
        "эшарп",
        "югрич",
        "югурт",
        "юдоль",
        "юзист",
        "юкола",
        "юниор",
        "юница",
        "юнкер",
        "юнкор",
        "юннат",
        "юноша",
        "юрага",
        "юрист",
        "юферс",
        "ябеда",
        "ягель",
        "ягода",
        "ягуар",
        "яичко",
        "яишня",
        "якорь",
        "ямина",
        "ямища",
        "ямщик",
        "ярица",
        "ярлык",
        "яруга",
        "ярыга",
        "ясень",
        "яспис",
        "яство",
        "ястык",
        "ясырь",
        "ятовь",
        "яхонт"],
      nouns: [
        "абзац",
        "аборт",
        "аванс",
        "авеню",
        "авось",
        "аврал",
        "агент",
        "адепт",
        "адрес",
        "азарт",
        "азиат",
        "айран",
        "акрил",
        "аксон",
        "актер",
        "актив",
        "акула",
        "акциз",
        "акция",
        "алиби",
        "алкаш",
        "аллея",
        "алмаз",
        "алыча",
        "альфа",
        "амбар",
        "амеба",
        "аминь",
        "ампер",
        "ангар",
        "ангел",
        "анонс",
        "аорта",
        "арбуз",
        "ареал",
        "арена",
        "арест",
        "ариец",
        "аркан",
        "армия",
        "архив",
        "аршин",
        "аскет",
        "астма",
        "атака",
        "атлас",
        "атлет",
        "афера",
        "афиша",
        "ацтек",
        "аэроб",
        "багаж",
        "багет",
        "багор",
        "бадья",
        "базар",
        "базис",
        "байка",
        "бакен",
        "балда",
        "балет",
        "балка",
        "банан",
        "банда",
        "банка",
        "барак",
        "баран",
        "баржа",
        "барий",
        "барин",
        "барон",
        "басня",
        "батат",
        "батон",
        "батут",
        "бачок",
        "башня",
        "бегун",
        "бедро",
        "бекон",
        "белка",
        "белок",
        "белье",
        "беляш",
        "берег",
        "берет",
        "бетон",
        "бидон",
        "бизон",
        "билет",
        "бином",
        "биржа",
        "бирка",
        "бисер",
        "битва",
        "биток",
        "благо",
        "бланк",
        "блеск",
        "блоха",
        "блуза",
        "блюдо",
        "бляха",
        "бобер",
        "богач",
        "бойня",
        "бокал",
        "болид",
        "бомба",
        "борец",
        "боров",
        "бочка",
        "браво",
        "брань",
        "брасс",
        "бремя",
        "брешь",
        "бридж",
        "бровь",
        "бронх",
        "броня",
        "брошь",
        "брюки",
        "брюхо",
        "бубен",
        "бугай",
        "бугор",
        "будка",
        "буква",
        "букет",
        "булка",
        "буран",
        "бутан",
        "бутса",
        "буфер",
        "буфет",
        "бухта",
        "быдло",
        "бытие",
        "бычок",
        "вагон",
        "вазон",
        "валет",
        "валун",
        "вальс",
        "ванна",
        "ванта",
        "варан",
        "варяг",
        "ватка",
        "вафля",
        "вахта",
        "вдова",
        "ведро",
        "венец",
        "веник",
        "венок",
        "вепрь",
        "верба",
        "верфь",
        "весло",
        "весна",
        "весть",
        "ветвь",
        "ветер",
        "ветка",
        "вечер",
        "вешка",
        "взвар",
        "взвод",
        "вздор",
        "вздох",
        "взлет",
        "взлом",
        "взмах",
        "взнос",
        "взрыв",
        "видео",
        "визаж",
        "визит",
        "вилка",
        "вилла",
        "вираж",
        "вирус",
        "виски",
        "висок",
        "виток",
        "вихрь",
        "вишня",
        "вклад",
        "влага",
        "вобла",
        "водка",
        "вожак",
        "вождь",
        "возня",
        "война",
        "вокал",
        "волга",
        "волна",
        "волос",
        "волхв",
        "вольт",
        "вопль",
        "ворон",
        "ворот",
        "ворох",
        "врата",
        "время",
        "вуаль",
        "вчера",
        "въезд",
        "выбор",
        "вывих",
        "вывод",
        "вывоз",
        "выгиб",
        "выгон",
        "выгул",
        "выдох",
        "выдра",
        "выезд",
        "вызов",
        "выкуп",
        "вылаз",
        "вылет",
        "вылов",
        "вылом",
        "вынос",
        "выпад",
        "вырез",
        "выход",
        "вычет",
        "вьюга",
        "вязка",
        "газон",
        "гайка",
        "галка",
        "галоп",
        "гамак",
        "гамма",
        "ганец",
        "гараж",
        "гейша",
        "гелий",
        "гений",
        "герой",
        "гетто",
        "гидра",
        "гиена",
        "глава",
        "гладь",
        "глина",
        "глист",
        "глубь",
        "глушь",
        "глыба",
        "глясе",
        "говор",
        "гогот",
        "голод",
        "голос",
        "гольф",
        "гонец",
        "гонка",
        "горло",
        "город",
        "горох",
        "гость",
        "грамм",
        "гранд",
        "грант",
        "грань",
        "графа",
        "греза",
        "грива",
        "гриль",
        "грипп",
        "гроза",
        "груда",
        "грудь",
        "грунт",
        "груша",
        "грыжа",
        "гряда",
        "грязь",
        "гуашь",
        "губка",
        "гудок",
        "гуляш",
        "гусар",
        "гусли",
        "гюрза",
        "давка",
        "дамба",
        "дамка",
        "дверь",
        "дебет",
        "дебош",
        "дебри",
        "дебют",
        "девиз",
        "декан",
        "декор",
        "дележ",
        "делец",
        "демон",
        "десна",
        "дефис",
        "джинн",
        "дзета",
        "дзюдо",
        "диван",
        "диета",
        "дикая",
        "длина",
        "днище",
        "добор",
        "довод",//t
        "догма",
        "дождь",
        "дозор",
        "дойка",
        "домен",
        "донор",
        "донос",
        "доска",
        "досуг",
        "досье",
        "доход",
        "дочка",
        "драже",
        "драка",
        "драма",
        "дрейф",
        "дрель",
        "дрема",
        "дробь",
        "дрова",
        "дрожь",
        "дрозд",
        "дрянь",
        "дубль",
        "дудка",
        "дупло",
        "дурак",
        "дуэль",
        "дымка",
        "дырка",
        "дюшес",
        "дятел",
        "евнух",
        "еврей",
        "егерь",
        "ересь",
        "ершик",
        "жажда",
        "жакет",
        "жарка",
        "жатва",
        "желоб",
        "желть",
        "желчь",
        "жених",
        "жердь",
        "жерло",
        "жесть",
        "жетон",
        "живец",
        "живот",
        "жизнь",
        "жилет",
        "жилец",
        "жилье",
        "жираф",
        "жирок",
        "житье",
        "жница",
        "жокей",
        "жрица",
        "жулик",
        "жулье",
        "жучок",
        "забег",
        "забой",
        "забор",
        "завал",
        "завет",
        "завод",
        "завоз",
        "завуч",
        "загар",
        "загиб",
        "загон",
        "загул",
        "задел",
        "задор",
        "заезд",
        "зажим",
        "зазор",
        "зазыв",
        "заказ",
        "закал",
        "закат",
        "закол",
        "закон",
        "закуп",
        "залет",
        "залив",
        "залог",
        "залом",
        "замах",
        "замер",
        "замес",
        "замет",
        "замок",
        "замор",
        "замша",
        "занос",
        "запад",
        "запал",
        "запас",
        "запах",
        "запев",
        "запой",
        "запор",
        "заряд",
        "засев",
        "засов",
        "засол",
        "засор",
        "засос",
        "затек",
        "затея",
        "затор",
        "заход",
        "зацеп",
        "зачес",
        "зачет",
        "звено",
        "зверь",
        "зебра",
        "зевок",
        "зелье",
        "земля",
        "зенит",
        "зерно",
        "зефир",
        "злато",
        "злоба",
        "злюка",
        "знамя",
        "знать",
        "зомби",
        "иврит",
        "игрок",
        "идеал",
        "идиот",
        "ижица",
        "избач",
        "извоз",
        "изгиб",
        "изгой",
        "излет",
        "излом",
        "измор",
        "износ",
        "изъян",
        "изыск",
        "икона",
        "икота",
        "имидж",
        "индюк",
        "инжир",
        "интим",
        "ирбис",
        "искра",
        "искус",
        "ислам",
        "испуг",
        "истец",
        "исток",
        "исход",
        "иудей",
        "кабан",
        "кагор",
        "кадет",
        "кадык",
        "казак",
        "казан",
        "казах",
        "казна",
        "казнь",
        "казус",
        "какао",
        "калач",
        "калий",
        "камин",
        "камыш",
        "канал",
        "канат",
        "канон",
        "каноэ",
        "канун",
        "капля",
        "капок",
        "капот",
        "каппа",
        "карат",
        "карга",
        "карта",
        "каска",
        "касса",
        "каста",
        "катер",
        "катет",
        "катод",
        "каток",
        "качка",
        "каюта",
        "квант",
        "кварк",
        "кварц",
        "квота",
        "кегль",
        "кегля",
        "келья",
        "кепка",
        "кефир",
        "кивок",
        "кизил",
        "кизяк",
        "кинза",
        "киоск",
        "кирка",
        "кисет",
        "киста",
        "кисть",
        "кифоз",
        "кишка",
        "класс",
        "клерк",
        "клест",
        "клише",
        "клоун",
        "книга",
        "князь",
        "коала",
        "кобра",
        "ковер",
        "кожух",
        "козел",
        "кознь",
        "койка",
        "койот",
        "кокос",
        "колба",
        "колея",
        "колик",
        "колок",
        "колос",//TODO
        "колун",
        "колье",
        "кольт",
        "комар",
        "комик",
        "комод",
        "комок",
        "конек",
        "конец",
        "конус",
        "конюх",
        "копия",
        "копка",
        "копье",
        "кореш",
        "короб",
        "кость",
        "косяк",
        "котел",
        "котик",
        "кофта",
        "кочан",
        "кочка",
        "кошка",
        "кощей",
        "кража",
        "краса",
        "кредо",
        "крест",
        "кровь",
        "крона",
        "кроха",
        "круиз",
        "крупа",
        "крыло",
        "крыса",
        "крыша",
        "кубик",
        "кубок",
        "кудри",
        "кузня",
        "кузов",
        "кукиш",
        "кукла",
        "кулак",
        "кулек",
        "кулич",
        "кулон",
        "культ",
        "кумир",
        "кумыс",
        "купаж",
        "купец",
        "купол",
        "купон",
        "кураж",
        "курок",
        "кусок",
        "кутеж",
        "кухня",
        "кучер",
        "кучка",
        "кювет",
        "лабаз",
        "лаваш",
        "лавка",
        "лавра",
        "ладан",
        "ладья",
        "лазер",
        "лайка",
        "лакей",
        "лампа",
        "лапка",
        "лапша",
        "ларек",
        "ласка",
        "лассо",
        "левша",
        "лейка",
        "лемма",
        "лемур",
        "лента",
        "лепет",
        "лепка",
        "лепра",
        "лепта",
        "леска",
        "лесть",
        "леший",
        "лиана",
        "лидер",
        "ликер",
        "лилия",
        "лимит",
        "лимон",
        "лимфа",
        "линза",
        "линия",
        "лирик",
        "литий",
        "литье",
        "лихач",
        "лицей",
        "лишай",
        "лобан",
        "лобби",
        "ловец",
        "ловля",
        "логик",
        "лодка",
        "ложка",
        "локон",
        "лопух",
        "лоток",
        "лотос",
        "лузга",
        "лунка",
        "лучик",
        "лучок",
        "лыжня",
        "лютик",
        "ляжка",
        "лямка",
        "магия",
        "магма",
        "мадам",
        "мажор",
        "мазня",
        "мазок",
        "мазут",
        "майер",
        "майка",
        "майор",
        "макет",
        "малек",
        "малец",
        "малыш",
        "маляр",
        "манго",
        "манеж",
        "мания",
        "манка",
        "манна",
        "манок",
        "манул",
        "марка",
        "марля",
        "маска",
        "масло",
        "масон",
        "масса",
        "масть",
        "матка",
        "мафия",
        "мачта",
        "медик",
        "мелок",
        "мерка",
        "место",
        "месть",
        "месье",
        "месяц",
        "метан",
        "метил",
        "метис",
        "метка",
        "метка",
        "метла",
        "метод",
        "метро",
        "мечта",
        "мешок",
        "мидия",
        "мимик",
        "минер",
        "минор",
        "минус",
        "миома",
        "мираж",
        "миска",//TODO
        "мишка",
        "мойва",
        "мойка",
        "молва",
        "молот",
        "моляр",
        "монах",
        "мопед",
        "морда",
        "мороз",
        "моряк",
        "мотив",
        "моток",
        "мотор",
        "мочка",
        "мошка",
        "мразь",
        "муаре",
        "мужик",
        "музей",
        "мулат",
        "муляж",
        "мумия",
        "мусор",
        "муфта",
        "мушка",
        "мымра",
        "мысль",
        "мытье",
        "мышца",
        "мэрия",
        "мякиш",
        "мямля",
        "мятеж",
        "набег",
        "набор",
        "навал",
        "навар",
        "навес",
        "навоз",
        "навык",
        "нагар",
        "нагиб",
        "надел",
        "надой",
        "надув",
        "наезд",
        "нажим",
        "наказ",
        "налет",
        "налог",
        "намаз",
        "намек",
        "нанос",
        "напев",
        "народ",
        "наряд",
        "насос",
        "натяг",
        "наука",
        "нахал",
        "нация",
        "начес",
        "невод",
        "недуг",
        "нерка",
        "нерпа",
        "нефть",
        "никто",
        "нимфа",
        "нитка",
        "ничто",
        "ничья",
        "ножка",
        "ножны",
        "номер",
        "норка",
        "норма",
        "носок",
        "нотка",
        "нужда",
        "нутро",
        "нырок",
        "нытик",
        "нытье",
        "нюанс",
        "оазис",
        "обвал",
        "обвес",
        "обвод",
        "обвоз",
        "обгон",
        "обдув",
        "обжиг",
        "обжим",
        "обжог",
        "обзор",
        "обида",
        "обкат",
        "облик",
        "облом",
        "обман",
        "обмен",
        "обнос",
        "образ",
        "обрез",
        "обруч",
        "обрыв",
        "обряд",
        "обсев",
        "обувь",
        "обуза",
        "обход",
        "объем",
        "обыск",
        "овраг",
        "огонь",
        "озеро",
        "озноб",
        "океан",
        "окись",
        "оклад",
        "оклик",
        "окрас",
        "окрик",
        "округ",
        "октет",
        "окунь",
        "олень",
        "олива",
        "олимп",
        "олово",
        "ольха",
        "омега",
        "омлет",
        "омуль",
        "опара",
        "опека",
        "опера",
        "опись",
        "опиум",
        "оплот",
        "опора",
        "опрос",
        "орава",
        "орган",
        "оргия",
        "орден",
        "ордер",
        "ореол",
        "орлан",
        "осада",
        "осень",
        "осетр",
        "осина",
        "оскал",
        "осмос",
        "особа",
        "особь",
        "осока",
        "остит",
        "остов",
        "отбой",
        "отбор",
        "отвал",
        "отвар",
        "ответ",
        "отгиб",
        "отгон",
        "отгул",
        "отдел",
        "отдых",
        "отель",
        "отжиг",
        "отжим",
        "отзыв",
        "отказ",
        "откат",
        "откос",
        "откуп",
        "отлив",
        "отлов",
        "отпад",
        "отпор",
        "отрез",
        "отрок",
        "отрыв",
        "отряд",
        "отсев",
        "отсек",
        "отсос",
        "отток",
        "отход",
        "отчет",
        "отчим",
        "офсет",
        "охват",
        "охота",
        "очерк",
        "ощупь",
        "падеж",
        "пайка",
        "пакет",
        "палас",
        "палач",
        "палец",
        "палка",
        "панно",
        "папка",
        "парад",
        "парик",
        "пария",
        "парок",
        "паром",
        "парта",
        "парус",
        "паста",
        "пасть",
        "пасха",
        "патио",
        "пауза",
        "пафос",
        "пацан",
        "пачка",
        "пашня",
        "певец",
        "пекан",
        "пекло",
        "пемза",
        "пенал",
        "пенек",
        "пение",
        "пенис",
        "пенни",
        "пепел",
        "перга",
        "перед",
        "перец",
        "перст",
        "песец",
        "песня",
        "песок",
        "петля",
        "петух",
        "печка",
        "пешка",
        "пижон",
        "пикап",
        "пикет",
        "пилон",
        "пилот",
        "пинок",
        "пинта",
        "пират",
        "пирог",
        "писец",
        "питон",
        "питье",
        "пихта",
        "пицца",
        "пламя",
        "пласт",
        "плата",
        "плато",
        "плеер",
        "племя",
        "плеск",
        "плеть",
        "плечо",
        "плешь",
        "плита",
        "плоть",
        "плохо",
        "побег",
        "повар",
        "повод",
        "погиб",
        "погон",
        "подол",
        "поезд",
        "пожар",
        "позор",
        "позыв",
        "пойка",
        "пойло",
        "поиск",
        "показ",
        "покер",
        "покой",
        "покос",
        "полет",
        "полив",
        "полип",
        "полис",
        "полка",
        "полом",
        "полюс",
        "поляк",
        "помет",//todo
        "помои",
        "помол",
        "помпа",
        "понос",
        "пончо",
        "попса",
        "порез",
        "порка",
        "порог",
        "порок",
        "порох",
        "порча",
        "порыв",
        "посад",
        "посев",
        "посол",
        "посох",
        "посыл",
        "потек",
        "поток",
        "потоп",
        "поход",
        "почва",
        "почет",
        "почин",
        "почка",
        "почта",
        "пошив",
        "поэма",
        "право",
        "пресс",
        "прием",
        "прима",
        "принц",
        "приор",
        "приют",
        "проба",
        "проем",
        "проза",
        "профи",
        "прыть",
        "прядь",
        "пряжа",
        "пряха",
        "псарь",
        "птица",
        "пуант",
        "пудра",
        "пульс",
        "пульт",
        "пункт",
        "пупок",
        "пурга",
        "пуфик",
        "пучок",
        "пушка",
        "пушок",
        "пчела",
        "пшено",
        "пытка",
        "пышка",
        "пьеса",
        "пятак",
        "пятка",
        "пятно",
        "пяток",
        "радар",
        "радий",
        "радио",
        "радон",
        "разок",
        "разум",
        "район",
        "рамка",
        "рампа",
        "ранец",
        "раунд",
        "рация",
        "рвота",
        "ребро",
        "ребус",
        "регби",
        "редан",
        "редис",
        "редут",
        "режим",
        "резак",
        "резец",
        "резка",
        "резня",
        "рейка",
        "рельс",
        "репер",
        "речка",
        "решка",
        "рикша",
        "рифма",
        "робот",
        "ровня",
        "рогач",
        "родня",
        "рожок",
        "рожон",
        "розга",
        "рознь",
        "ройка",
        "рокер",
        "рокот",
        "ролик",
        "роман",
        "ропот",
        "ротор",
        "рояль",
        "ртуть",
        "рубеж",
        "рубец",
        "рубин",//todo
        "рубка",
        "рубль",
        "ружье",
        "руина",
        "рукав",
        "рулет",
        "рулон",
        "рупия",
        "рупор",
        "русло",
        "ручей",
        "ручка",
        "рыбак",
        "рывок",
        "рынок",
        "рытье",
        "рычаг",
        "рюмка",
        "сабля",
        "садик",
        "сазан",
        "салат",
        "салон",
        "салют",
        "самбо",
        "самец",
        "самка",
        "санки",
        "сапер",
        "сапог",
        "сарай",
        "сауна",
        "сахар",
        "сачок",
        "сброд",
        "сброс",
        "сваха",
        "свеча",
        "свист",
        "свита",
        "свора",
        "свояк",
        "связь",
        "сглаз",
        "сдача",
        "сдвиг",
        "сдоба",
        "сеанс",
        "север",
        "седло",
        "сезон",
        "секта",
        "семга",
        "семья",
        "сенат",
        "серия",
        "сетка",
        "сигма",
        "силач",
        "силок",
        "синус",
        "синяк",
        "сироп",
        "сифон",
        "скала",
        "скаут",
        "сквер",
        "скейт",
        "склад",
        "склеп",
        "склон",
        "скоба",
        "скотч",
        "скреп",
        "скрип",
        "скука",
        "скула",
        "скунс",
        "слава",
        "слайд",
        "слеза",
        "сленг",
        "слива",
        "слизь",
        "слово",
        "слуга",
        "слюда",//todo
        "слюна",
        "смена",
        "смерч",
        "смесь",
        "смета",
        "смола",
        "смрад",
        "смута",
        "смысл",
        "сноха",
        "собор",
        "совет",
        "совок",
        "созыв",
        "сойка",
        "сокол",
        "солод",
        "сопка",
        "сопло",
        "сопля",
        "сорок",
        "сосед",
        "соска",
        "сосна",
        "сосок",
        "сосуд",
        "сотня",
        "спазм",
        "спина",
        "спирт",
        "спица",
        "сплав",
        "сплин",
        "спора",
        "спорт",
        "спрей",
        "спрос",
        "спуск",
        "среда",
        "ссора",
        "ссуда",
        "стадо",
        "сталь",
        "старт",
        "ствол",
        "створ",
        "стезя",
        "стела",
        "стена",
        "стенд",
        "степь",
        "стиль",
        "столб",
        "столп",
        "стопа",
        "стояк",
        "страж",
        "страх",
        "стриж",
        "строй",
        "струя",
        "стужа",
        "судак",
        "судно",
        "судья",
        "суета",
        "сукно",
        "сумка",
        "сумма",
        "супер",
        "сурок",
        "сутки",
        "суфле",
        "сучок",
        "сушка",
        "сфера",
        "схема",
        "сцена",
        "съезд",
        "сынок",
        "сырок",
        "сырье",
        "сыщик",
        "сюжет",
        "табак",
        "табло",
        "табун",
        "тайга",
        "тайна",
        "такса",
        "такси",
        "талия",
        "тальк",
        "танго",
        "танец",
        "тапка",
        "таран",
        "тариф",
        "тачка",
        "тварь",
        "театр",
        "тезис",
        "тезка",
        "текст",
        "телец",
        "телик",
        "телка",
        "тембр",
        "тенор",
        "тепло",
        "терем",
        "терка",
        "тесак",
        "тесто",
        "тесть",
        "тетка",
        "течка",
        "тиара",
        "тигра",
        "типаж",
        "тираж",
        "тиран",
        "тиски",
        "титан",
        "титул",
        "ткань",
        "товар",
        "толпа",//todo
        "толща",
        "томат",
        "тоник",
        "тонна",
        "тонус",
        "топик",
        "топка",
        "топор",
        "топот",
        "торец",
        "тоска",
        "тотем",
        "точка",
        "трава",
        "тракт",
        "трапп",
        "трата",
        "траур",
        "трель",
        "треск",
        "треть",
        "трико",
        "тромб",
        "тропа",
        "труба",
        "тубус",
        "тузик",
        "тукан",
        "тулий",
        "тулуп",
        "туман",
        "тумба",
        "тунец",
        "тупик",
        "турка",
        "турне",
        "турок",
        "туфля",
        "тушка",
        "тыква",
        "тюбик",
        "тягач",
        "тяжба",
        "уазик",
        "убыль",
        "уголь",
        "угорь",
        "удача",
        "удило",
        "узбек",
        "узник",
        "уклон",
        "укроп",
        "уксус",
        "улика",
        "улица",
        "умник",
        "умора",
        "унция",
        "упрек",
        "упырь",
        "урина",
        "успех",
        "устав",
        "устой",
        "устье",
        "утеря",
        "утеха",
        "утиль",
        "ухват",
        "учеба",
        "ущерб",
        "фазан",
        "факел",
        "фанат",
        "фасад",
        "фасон",
        "фауна",
        "ферзь",
        "ферма",
        "фетиш",
        "фибра",
        "физик",
        "фикус",
        "филин",
        "фильм",
        "финал",
        "финик",
        "финиш",
        "финка",
        "фирма",
        "фишка",
        "фланг",
        "флирт",
        "флора",//todo
        "флюид",
        "фляга",
        "фобия",
        "фокус",
        "форма",
        "форум",
        "фотон",
        "фраер",
        "фраза",
        "франк",
        "фрахт",
        "фреза",
        "фронт",
        "фрукт",
        "фугас",
        "фужер",
        "фурия",
        "фурор",
        "фуфло",
        "фьорд",
        "фюрер",
        "хакер",
        "халат",
        "халва",
        "ханжа",
        "харчо",
        "хвала",
        "хворь",
        "хвост",
        "херес",
        "химик",
        "химия",
        "хинди",
        "хинин",
        "хиппи",
        "хитин",
        "хлест",
        "хлюст",
        "хмель",
        "хобби",
        "хобот",
        "холка",
        "холод",
        "холоп",
        "холст",
        "хомут",
        "хомяк",
        "хорда",
        "хорек",
        "хохма",
        "хохол",
        "хохот",
        "хруст",
        "хумус",
        "хунта",
        "хурма",
        "хутор",
        "цапля",
        "цацка",
        "цедра",
        "цезий",
        "целое",
        "центр",
        "цинга",
        "циник",
        "циста",
        "цифра",
        "цокот",
        "цукат",
        "цыган",
        "чайка",
        "чалма",
        "часть",
        "чашка",
        "челка",
        "чепец",
        "черва",
        "червь",
        "черед",
        "череп",
        "чернь",
        "черта",
        "честь",
        "четка",
        "чехол",
        "чечен",
        "чешка",
        "чешуя",
        "чижик",
        "число",
        "чрево",
        "чреда",
        "чтиво",
        "чугун",
        "чудак",
        "чудик",
        "чужак",
        "чукча",
        "чулан",
        "чулок",
        "чутье",
        "шабат",
        "шавка",
        "шайба",
        "шайка",
        "шакал",
        "шалаш",
        "шалун",
        "шаман",
        "шапка",
        "шарик",
        "шасси",
        "шатен",
        "шатер",
        "шатун",
        "шахта",
        "шашка",
        "шваль",
        "шверт",
        "шейка",
        "шельф",
        "шепот",
        "шериф",
        "ширма",
        "шитье",
        "шифер",
        "шишка",
        "шкала",
        "шквал",
        "шкерт",
        "школа",
        "шкура",
        "шланг",
        "шлейф",
        "шлюха",
        "шляпа",
        "шмель",
        "шнапс",
        "шорох",
        "шоссе",
        "шофер",
        "шпага",
        "шпала",
        "шпана",
        "шпиль",
        "шпион",
        "шпора",
        "шприц",
        "шпрот",
        "шрифт",
        "штамм",
        "штамп",
        "штаны",
        "штиль",
        "штифт",
        "штора",
        "шторм",
        "штраф",
        "штрих",
        "штука",
        "штурм",
        "шулер",
        "шурин",
        "шурпа",
        "шуруп",
        "шутка",
        "шхуна",
        "щебет",
        "щегол",
        "щекот",
        "щелка",
        "щенок",
        "щепка",
        "щетка",
        "щечка",
        "щипок",
        "эгида",
        "эклер",
        "экран",
        "элита",
        "эмаль",
        "эпоха",
        "эркер",
        "эскиз",
        "эстет",
        "этика",
        "этнос",
        "эфиоп",
        "юниор",
        "юнкер",
        "юноша",
        "юрист",
        "ябеда",
        "ягель",
        "ягода",
        "ягуар",
        "якорь",
        "ямщик",
        "ярлык",
        "ярыга",
        "ясень",
      ],
      rules,
    }
  },
  computed: {
    filledCount() {
      return this.rules.filter(r => r.word.length === 5).length;
    }
  },
  mounted() {
    this.rules[0].word = this.nextWords(this.rules, this.nouns).words[0].word;
    this.$forceUpdate();
  },
  // watch: {
  //   filledCount() {
  //     let word = this.nextWords(this.rules.slice(0, this.filledCount - 1), this.nouns).words[0];
  //     if (!word) return;
  //     this.rules[this.filledCount].word = word.word;
  //     this.$forceUpdate();
  //   }
  // },
  methods: {
    fillNextWord() {
      // this.$nextTick(() => {
      //   let word = this.nextWords(this.rules.slice(0, this.filledCount - 1), this.nouns).words[0];
      //   if (!word) return;
      //   this.rules[this.filledCount].word = word.word;
      //   this.$forceUpdate();
      // })
    },
    filterByRules(rules, nouns) {
      let result = [];
      nouns.forEach(noun => {
        let isOk = true;
        rules.filter(r => r.word).forEach(rule => {
          let counts = {};
          let countsNoun = {};
          for (let i = 0; i < rule.word.length; ++i) {
            let type = +rule.info[i];
            let word = rule.word;
            let letter = rule.word[i].toLowerCase();
            if (type > 0) counts[letter] = (counts[letter] || 0) + 1;
            if (noun == 'гость' && rules.length == 3) console.log({noun, letter, s: word.substring(i + 1)})
            if (type === 0 && noun.includes(letter) && !word.substring(i + 1).includes(letter)) {
              isOk = false;
              return false;
            } else if (type === 1 && (!noun.includes(letter) || noun[i] === letter)) {
              isOk = false;
              return false;
            } else if (type === 2 && noun[i] !== letter) {
              isOk = false;
              return false;
            }
          }
          noun.split('').forEach(letter => {
            countsNoun[letter] = (countsNoun[letter] || 0) + 1;
          });
          for (let letter in counts) {
            if (counts[letter] > countsNoun[letter]) {
              isOk = false;
              return false;
            }
          }
        });
        if (isOk) result.push(noun);
      });
      return result;
    },
    ignoreByRules(rules) {
      let ignore = [];
      rules.forEach(rule => {
        let letters = rule.word.split('');
        letters.forEach(l => {
          if (!ignore.includes(l)) ignore.push(l);
        })
      });
      return ignore;
    },
    freq(words, rules) {
      let result = {};
      let ignore = this.ignoreByRules(rules);
      for (let i in words) {
        for (let j = 0; j < words[i].length; ++j) {
          let l = words[i][j];
          if (!result[l]) result[l] = {l: l, count: 0};
          if (!ignore.includes(l)) result[l].count++;
        }
      }
      return result;
    },
    nextWords(rules, nouns) {
      let words = this.filterByRules(rules, nouns);
      let freq = this.freq(words, rules);
      let result = [];
      this.nouns.forEach(word => {
        if (result[word]) return;
        result[word] = {word, score: 0};
        let letters = word.split('').filter((l, i) => word.indexOf(l) === i)
        for (let i in letters) {
          result[word].score += freq[letters[i]] ? freq[letters[i]].count : 0;
        }
      });
      result = Object.values(result);
      result.sort((a, b) => b.score - a.score);
      console.log(words);
      return {words: result.slice(0, 13), count: words.length, answer: words.slice(0, 10)};
    }
  },
}
</script>

<style lang="scss">
#app {
  font-family: Arial;
  background: #fff3ed;
}
</style>
